import { makeArray } from './Model';
import { PodReportingData } from './PodReportingData';

export class PodReportingDataExtended extends PodReportingData {
  subtotals?: PodReportingDataExtended[];

  constructor(obj: any) {
    super(obj);
    this.subtotals = makeArray(obj.subtotals, PodReportingDataExtended)?.sort(
      PodReportingDataExtended.sort,
    );
  }
}

export default PodReportingDataExtended;
