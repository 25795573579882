import classNames from 'classnames';
import React from 'react';

import { makeStyles } from '@mui/styles';

import { PodMetricsSummary } from '@vestahealthcare/common/models';
import { PodMetricsSummaryTask } from '@vestahealthcare/common/models/PodMetricsSummaryTask';

import { Chip, Fonts, Tooltip } from 'styleguide-v2';

import { getTaskColor } from '../utils';
import { COLUMN_SIZE } from './constants';

type Props = {
  data: PodMetricsSummary[];
};

const useStyles = makeStyles({
  chart: {
    margin: '1rem auto',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  itemContainer: {
    display: 'flex',
    flexFlow: 'column',
    flexWrap: 'wrap',
    gap: '0.5rem',
    padding: '2rem 4rem',
    width: `${COLUMN_SIZE}px`,
  },
  text: {
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 500,
    maxWidth: `${COLUMN_SIZE - 110}px`,
  },
});

export const PODSummaryTableTask = ({ data }: Props) => {
  const styles = useStyles();

  const renderChip = (data: PodMetricsSummaryTask) => (
    <Tooltip text={`${data.completed}/${data.total}`}>
      <div>
        <Chip
          className="semi-bold"
          color={getTaskColor((data.completed / data.total) * 100)}
          type="contained-2"
        >
          {Math.round((data.completed / data.total) * 100)}%
        </Chip>
      </div>
    </Tooltip>
  );

  return (
    <div className={styles.container}>
      {data.map((pod, idx) => (
        <div
          className={styles.itemContainer}
          key={`pod-member-allocation-${idx}`}
        >
          {pod.taskStatusCounts.map((task, taskIdx) => (
            <div
              className="flex space-between gap"
              key={`pod-${idx}-task-${taskIdx}`}
            >
              <Tooltip text={task.name}>
                <div className={classNames(styles.text, 'ellipsis')}>
                  {task.name}
                </div>
              </Tooltip>
              {renderChip(task)}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
