import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import PeopleIcon from '@mui/icons-material/PeopleAltOutlined';

import { translate } from '@vestahealthcare/common/i18n';
import {
  PodMetricsSummary,
  PodMetricsSummaryEmployee,
} from '@vestahealthcare/common/models';

import {
  Collapse,
  CollapseIcon,
  Colors,
  IconButton,
  Spinner,
} from 'styleguide-v2';

import { PodSummaryCardMemberAllocationTable } from './PodSummaryCardMemberAllocationTable';
import { PODSummaryCardStyles } from './styles';

type Props = {
  className?: string;
  pod: PodMetricsSummary;
  employees?: PodMetricsSummaryEmployee[];
  employeesLoading?: boolean;

  onOpenEmployees: () => void;
};

export const PodSummaryCardMemberAllocation = ({
  className,
  pod,
  employees,
  onOpenEmployees,
}: Props) => {
  const styles = PODSummaryCardStyles();
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleOpenDetail = () => {
    if (!employees?.length && !openDetail) {
      setLoading(true);
      onOpenEmployees();
    } else {
      setLoading(false);
      setOpenDetail(!openDetail);
    }
  };

  useEffect(() => {
    if (employees?.length) {
      setOpenDetail(true);
      setLoading(false);
    }
  }, [employees]);

  return (
    <div className={className}>
      <div
        className={classNames(styles.itemContainer, 'clickable')}
        onClick={() => setOpenDetail(!openDetail)}
      >
        <span className={styles.subtitle}>
          {translate('pods.metrics.card.memberAllocation')}
        </span>
        <div className={styles.titleInfo}>
          <PeopleIcon
            htmlColor={
              pod.memberCount > pod.maxMembers ? Colors.error : Colors.iconGreen
            }
            fontSize="large"
          />
          <span
            className={classNames(
              styles.textWithIcon,
              pod.memberCount > pod.maxMembers && 'red',
            )}
          >
            {pod.memberCount}/{pod.maxMembers}
          </span>
          <IconButton
            className={styles.titleInfoCollapse}
            onClick={() => handleOpenDetail()}
            size="small"
          >
            {loading ? (
              <div className="flex center" style={{ width: 25 }}>
                <Spinner width={20} />
              </div>
            ) : (
              <CollapseIcon open={openDetail} />
            )}
          </IconButton>
        </div>
      </div>
      <Collapse open={openDetail}>
        <div className={classNames(styles.itemContainer, 'flex')}>
          <PodSummaryCardMemberAllocationTable employees={employees || []} />
        </div>
      </Collapse>
    </div>
  );
};
