import classNames from 'classnames';
import React from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import {
  PodReportingAherenceData,
  PodReportingData,
  PodReportingRPM,
  ProgramExtensionStatus,
} from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { Colors, Summary } from 'styleguide-v2';

import { PodReportingAdherenceTable } from './PodReportingAdherenceTable';
import { PodReportingDataTable } from './PodReportingDataTable';
import { PodReportingStyles } from './PodReportingStyles';

type Props = {
  data?: PodReportingRPM;

  onClickActive: () => void;
  onClickInProgress: () => void;
  onClickNotStarted: () => void;
  onClickReadyForOutreach: () => void;
  onClickTotal: () => void;
  onClickAdherence: (item: PodReportingAherenceData) => void;
  onClickStatus: (item: PodReportingData) => void;
  peStatuses: ProgramExtensionStatus[];
};

export const PodReportingRPMPage = ({
  data,
  onClickActive,
  onClickInProgress,
  onClickNotStarted,
  onClickReadyForOutreach,
  onClickTotal,
  onClickAdherence,
  onClickStatus,
  peStatuses,
}: Props) => {
  const styles = PodReportingStyles();
  if (!data) return <></>;

  const { adherence, status, summary } = data;

  return (
    <>
      <div
        className={classNames(
          'flex spaced',
          styles.borderBottom,
          styles.paddingContainer,
        )}
      >
        <Summary
          color={Colors.textGreen}
          title={translate('podReporting.summary.total')}
          body={summary?.total !== undefined ? summary.total : EMPTY}
          footer={
            <>
              <span className="bold">
                {summary?.total
                  ? Math.round((summary.total / summary.activeMembers) * 1000) /
                    10
                  : EMPTY}
                %
              </span>{' '}
              {translate('podReporting.summary.ofPatientsActive')}
            </>
          }
          onClick={onClickTotal}
        />
        <Summary
          color={Colors.iconGreen}
          title={translate('podReporting.summary.active')}
          body={summary?.active !== undefined ? summary.active : EMPTY}
          footer={
            <>
              <span className="bold">
                {summary?.total
                  ? Math.round(
                      (summary.active / summary.activeMembers) * 1000,
                    ) / 10
                  : EMPTY}
                %
              </span>{' '}
              {translate('podReporting.summary.ofPatientsActive')}
            </>
          }
          onClick={onClickActive}
        />
        <Summary
          color={Colors.iconGray}
          title={translate('podReporting.summary.notStarted')}
          body={summary?.notStarted !== undefined ? summary.notStarted : EMPTY}
          footer={
            <>
              <span className="bold">
                {summary?.total
                  ? Math.round(
                      (summary.notStarted / summary.activeMembers) * 1000,
                    ) / 10
                  : EMPTY}
                %
              </span>{' '}
              {translate('podReporting.summary.ofPatientsActive')}
            </>
          }
          onClick={onClickNotStarted}
        />
        <Summary
          color={Colors.gold}
          title={translate('podReporting.summary.inProgress')}
          body={summary?.inProgress !== undefined ? summary.inProgress : EMPTY}
          footer={
            <>
              <span className="bold">
                {summary?.total
                  ? Math.round(
                      (summary.inProgress / summary.activeMembers) * 1000,
                    ) / 10
                  : EMPTY}
                %
              </span>{' '}
              {translate('podReporting.summary.ofPatientsActive')}
            </>
          }
          onClick={onClickInProgress}
        />
        <Summary
          color={Colors.gold}
          title={translate('podReporting.summary.readyForOutreach')}
          body={
            summary?.readyForOutreach !== undefined
              ? summary.readyForOutreach
              : EMPTY
          }
          footer={
            <>
              <span className="bold">
                {summary?.total
                  ? Math.round(
                      (summary.readyForOutreach / summary.activeMembers) * 1000,
                    ) / 10
                  : EMPTY}
                %
              </span>{' '}
              {translate('podReporting.summary.ofPatientsActive')}
            </>
          }
          onClick={onClickReadyForOutreach}
        />
      </div>
      <div className={classNames('grid-wrapper', styles.borderContainer)}>
        <div className="grid-span-6">
          <div className="flex gap">
            <h2 className={styles.subtitle}>
              {translate('podReporting.titleRPMAdherence')}
            </h2>
          </div>
          <PodReportingAdherenceTable
            boldLastRow
            data={adherence}
            header={translate('podReporting.table.opportunityLevel')}
            onClick={onClickAdherence}
          />
        </div>
        <div className="grid-span-6">
          <div className="flex gap">
            <h2 className={styles.subtitle}>
              {translate('podReporting.titleRPMStatus')}
              {' - '}
              <span className={styles.subsubtitle}>
                {translate('podReporting.monthDoesNotApply')}
              </span>
            </h2>
          </div>
          <PodReportingDataTable
            data={status.map((item) => ({
              ...item,
              name:
                peStatuses?.find(({ id }) => id === item.name)?.description ||
                item.name,
            }))}
            header={translate('podReporting.table.status')}
            onClick={onClickStatus}
          />
        </div>
      </div>
    </>
  );
};
