import React from 'react';

import { Selectable, SelectableInfo } from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import { Employee } from '@vestahealthcare/common/models';

import { FilterItem, FilterSelectable, Select } from 'styleguide-v2';

import { GetPodReportDataParams } from 'dash/src/services/CCMServices';
import { getFilterItem } from 'dash/src/utils/filterUtils';

type KeyGetPodReportParams = keyof GetPodReportDataParams;

type Props = {
  data: {
    employees: SelectableInfo<Employee>[];
    languages: Selectable[];
    programExtensions: Selectable[];
    status: Selectable[];
    worklistGroups: Selectable[];
  };
  disabledFilters: KeyGetPodReportParams[];
  filters?: {
    [x in KeyGetPodReportParams]?: FilterItem;
  };
  loading?: boolean;
  onChange: (
    filters: {
      [x in KeyGetPodReportParams]?: FilterItem;
    },
  ) => void;
  selectedPE?: number;
};

export const PodReportingFiltersBar = ({
  data,
  disabledFilters,
  filters,
  loading,
  onChange,
  selectedPE,
}: Props) => (
  <>
    <Select
      data-cy="pod-filter-bar-status"
      disabled={disabledFilters.includes('status')}
      items={data.status}
      limitTags={1}
      multiple
      onChange={(values: Selectable[]) =>
        onChange({
          ...filters,
          status: getFilterItem(
            translate('podReporting.filters.memberStatus'),
            values,
          ),
        })
      }
      placeholder={translate('podReporting.filters.memberStatus')}
      placeholderV2
      size="xs"
      value={filters?.status?.value || []}
    />
    <Select
      data-cy="pod-filter-bar-pod"
      disabled={disabledFilters.includes('npOwnerId')}
      items={data.employees?.filter((e) => e.info?.isPodManager || !e.value)}
      limitTags={1}
      loading={loading}
      multiple
      onChange={(values: Selectable[]) =>
        onChange({
          ...filters,
          podManagerId: getFilterItem(
            translate('podReporting.filters.podManager'),
            values,
          ),
        })
      }
      placeholder={translate('podReporting.filters.podManager')}
      placeholderV2
      size="xs"
      value={filters?.podManagerId?.value || []}
    />
    <Select
      data-cy="pod-filter-bar-wlg"
      disabled={disabledFilters.includes('worklistGroupId')}
      items={data.worklistGroups}
      limitTags={1}
      loading={loading}
      multiple
      onChange={(values: Selectable[]) =>
        onChange({
          ...filters,
          worklistGroupId: getFilterItem(
            translate('podReporting.filters.groups'),
            values,
          ),
        })
      }
      placeholder={translate('podReporting.filters.groups')}
      placeholderV2
      size="xs"
      value={filters?.worklistGroupId?.value || []}
    />
    <Select
      data-cy="pod-filter-bar-language"
      disabled={disabledFilters.includes('language')}
      items={data.languages.filter(({ disabled }) => !disabled)}
      grouped={({ preferred }) =>
        preferred ? translate('global.preferred') : translate('global.other')
      }
      limitTags={1}
      loading={loading}
      multiple
      onChange={(values: Selectable[]) =>
        onChange({
          ...filters,
          language: getFilterItem(
            translate('podReporting.filters.language'),
            values,
          ),
        })
      }
      placeholder={translate('podReporting.filters.language')}
      placeholderV2
      size="xs"
      value={filters?.language?.value || []}
    />
    <Select
      data-cy="pod-filter-exclude-pe"
      disabled={disabledFilters.includes('excludedProgramExtensionId')}
      getItemDisabled={({ value }: Selectable) => value === selectedPE}
      items={data.programExtensions}
      limitTags={1}
      loading={loading}
      multiple
      onChange={(values: Selectable[]) =>
        onChange({
          ...filters,
          excludedProgramExtensionId: getFilterItem(
            translate('podReporting.filters.excludedProgramExtensions'),
            values,
          ),
        })
      }
      placeholder={translate('podReporting.filters.excludedProgramExtensions')}
      placeholderV2
      size="xs"
      value={filters?.excludedProgramExtensionId?.value || []}
    />
    <Select.Choice
      data-cy="pod-filter-bar-unreachable"
      disabled={disabledFilters.includes('isUnreachable')}
      disableClearable
      limitTags={1}
      loading={loading}
      onChange={(value?: boolean) =>
        onChange({
          ...filters,
          isUnreachable: getFilterItem(
            translate('podReporting.filters.unreachable'),
            value,
          ),
        })
      }
      placeholder={translate('podReporting.filters.unreachable')}
      placeholderV2
      size="xs"
      value={
        (filters?.isUnreachable?.value as FilterSelectable)?.value as boolean
      }
    />
  </>
);
