import classNames from 'classnames';
import React from 'react';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { PodReportingData } from '@vestahealthcare/common/models';

import { Fonts, Table } from 'styleguide-v2';
import { Column } from 'styleguide-v2/src/components/Table';

interface Props {
  boldLastRow?: boolean;
  className?: string;
  data: PodReportingData[];
  header: string;
  onClick?: (data: PodReportingData) => void;
}

const TOTAL = 'Total';

const POD_REPORTING_DATA_COLUMNS = ({
  boldLastRow,
  data,
  header,
  onClick,
}: Props) => {
  const columns = [
    {
      headerName: header,
      component: ({ name }: PodReportingData, index: number) => (
        <span
          style={{
            fontWeight: boldLastRow && index === data?.length - 1 ? 500 : 400,
          }}
        >
          {name}
        </span>
      ),
    },
    {
      headerName: translate('podReporting.table.total'),
      component: ({ total }: PodReportingData, index: number) => (
        <span
          style={{
            fontWeight: boldLastRow && index === data?.length - 1 ? 500 : 400,
          }}
        >
          {total}
        </span>
      ),
      width: 100,
    },
  ];
  const clickableColumn = {
    headerName: '',
    component: ({ name }: PodReportingData) =>
      name !== TOTAL ? (
        <ArrowForwardIosIcon color="action" fontSize="small" />
      ) : (
        <></>
      ),
    width: 20,
  };

  return [...columns, onClick ? clickableColumn : undefined].filter(
    Boolean,
  ) as Column[];
};

const useStyles = makeStyles({
  table: {
    '&& tbody': {
      '& td': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      },
      '& a': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      },
    },
  },
});

export const PodReportingDataTable = (props: Props) => {
  const styles = useStyles();

  return (
    <Table
      className={classNames(props.className, styles.table)}
      config={{
        columns: POD_REPORTING_DATA_COLUMNS(props),
        compact: true,
        data: props.data,
        paginationOptions: [-1],
      }}
      onClickRow={
        props.onClick
          ? (data: PodReportingData) =>
              data.name !== TOTAL && props.onClick && props.onClick(data)
          : undefined
      }
      whiteBackground
    />
  );
};

export default PodReportingDataTable;
