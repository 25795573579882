import {
  EmployeeRole,
  Language,
  ProgramExtensionStatusReason,
  States,
} from '../enums';
import { Employee } from './Employee';
import { make, makeArray } from './Model';
import { PodMetricsSummaryAllocation } from './PodMetricsSummaryAllocation';
import { PodMetricsSummaryData } from './PodMetricsSummaryData';
import { PodMetricsSummaryTask } from './PodMetricsSummaryTask';

const getTopThree = (items: PodMetricsSummaryData[]) => {
  if (items.length > 3) {
    const topThree = [...items].slice(0, 3);
    const other = [...items].slice(4);
    return [
      ...topThree,
      other?.reduce(
        (acc, item) => {
          acc.count += item.count;
          return acc;
        },
        { item: undefined, count: 0 } as PodMetricsSummaryData,
      ),
    ];
  }
  return items;
};
export class PodMetricsSummary {
  id = 0;

  name = '';

  manager: Employee;

  /** MEMBERS */

  memberCount = 0;

  maxMembers = 0;

  unreachableCount = 0;

  languageCounts: PodMetricsSummaryData[];

  stateCounts: PodMetricsSummaryData[];

  /** PROGRAMS */

  ccmCount = 0;

  ccmCompletedCount = 0;

  ccmNotStartedCount = 0;

  ccmAttemptedCount = 0;

  ccmHoldCount = 0;

  rpmCount = 0;

  rpmAtRiskCount = 0;

  rpmOnTrackCount = 0;

  rpmMetCount = 0;

  tocCount = 0;

  tocDueCount = 0;

  tocDuePenCount = 0;

  tocDueCompCount = 0;

  tocDueRSCount = 0;

  skilledCount = 0;

  persCount = 0;

  onHoldCCMCounts: PodMetricsSummaryData[];

  /** UNREACHABLE */

  unreachable30Count = 0;

  unreachable3060Count = 0;

  unreachable6090Count = 0;

  unreachable90Count = 0;

  /** TASKS */
  tasksCount = 0;

  tasksCompletedCount = 0;

  taskStatusCounts: PodMetricsSummaryTask[];

  /** BIANNUAL */
  npBiannualDueCount = 0;

  npBiannualOverdueCount = 0;

  npBiannualPendingCount = 0;

  npBiannualCompletedCount = 0;

  npBiannualRescheduleDueCount = 0;

  npBiannualRescheduleOverdueCount = 0;

  npBiannualNotYetDueUnreachableCount = 0;

  npBiannualNotYetDueReachableCount = 0;

  npBiannualNotYetOverdueUnreachableCount = 0;

  npBiannualNotYetOverdueReachableCount = 0;

  /** ALLOCATION */

  npAllocation: PodMetricsSummaryAllocation;

  rnAllocation: PodMetricsSummaryAllocation;

  hcAllocation: PodMetricsSummaryAllocation;

  cmaAllocation: PodMetricsSummaryAllocation;

  engagementAllocation: PodMetricsSummaryAllocation;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.cmaAllocation = new PodMetricsSummaryAllocation(
      EmployeeRole.MA.value,
      obj.employeeAllocationCounts,
      obj.employeeCapacityCounts,
    );
    this.engagementAllocation = new PodMetricsSummaryAllocation(
      EmployeeRole.ENGAGEMENT_COORDINATOR.value,
      obj.employeeAllocationCounts,
      obj.employeeCapacityCounts,
    );
    this.hcAllocation = new PodMetricsSummaryAllocation(
      EmployeeRole.HEALTH_COACH.value,
      obj.employeeAllocationCounts,
      obj.employeeCapacityCounts,
    );
    this.languageCounts = makeArray(
      obj.languageCounts.map((language: any) => ({
        item: Language.byKey[language.item as string],
        count: language.count,
      })),
      PodMetricsSummaryData,
    ).sort(PodMetricsSummaryData.sort);
    this.manager = make(obj.manager, Employee);
    this.npAllocation = new PodMetricsSummaryAllocation(
      EmployeeRole.NP.value,
      obj.employeeAllocationCounts,
      obj.employeeCapacityCounts,
    );
    this.onHoldCCMCounts = makeArray(
      obj.onHoldCCMCounts.map((state: any) => ({
        item: ProgramExtensionStatusReason.byKey[state.item as string],
        count: state.count,
      })),
      PodMetricsSummaryData,
    ).sort(PodMetricsSummaryData.sort);
    this.rnAllocation = new PodMetricsSummaryAllocation(
      EmployeeRole.RN.value,
      obj.employeeAllocationCounts,
      obj.employeeCapacityCounts,
    );
    this.stateCounts = makeArray(
      obj.stateCounts.map((state: any) => ({
        item: States.byKey[state.item as string],
        count: state.count,
      })),
      PodMetricsSummaryData,
    ).sort(PodMetricsSummaryData.sort);
    this.taskStatusCounts = Object.entries(obj.taskStatusCounts || {}).map(
      ([key, value]) => new PodMetricsSummaryTask(key, value),
    );
  }

  get ccmCompletedPercetage() {
    return Math.round((this.ccmCompletedCount / (this.ccmCount || 1)) * 100);
  }

  get rpmCompletedPercetage() {
    return Math.round(
      ((this.rpmMetCount + this.rpmOnTrackCount) / (this.rpmCount || 1)) * 100,
    );
  }

  get tocCompletedPercetage() {
    return Math.round((this.tocDueCompCount / (this.tocCount || 1)) * 100);
  }

  get taskCompletedPercetage() {
    return Math.round(
      (this.tasksCompletedCount / (this.tasksCount || 1)) * 100,
    );
  }

  get topThreeLanguages() {
    return getTopThree(this.languageCounts);
  }

  get topThreeStates() {
    return getTopThree(this.stateCounts);
  }
}

export default PodMetricsSummary;
