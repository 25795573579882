import classNames from 'classnames';
import React from 'react';

import { translate } from '@vestahealthcare/common/i18n';

import { PODSummaryCardStyles } from './styles';

type Props = {
  className?: string;
};

export const PodSummaryCardWarnings = ({ className }: Props) => {
  const styles = PODSummaryCardStyles();

  return (
    <div className={className}>
      <div className={styles.itemContainer}>
        <span className={styles.subtitle}>
          {translate('pods.metrics.card.warnings')}
        </span>
        <div className={styles.titleInfo}>
          <span
            className={classNames(
              styles.textWithIcon,
              'italic grey font-normal',
            )}
          >
            Comming Soon
          </span>
        </div>
      </div>
    </div>
  );
};
