import { Enum } from './Enum';

export class EncounterMethodOutcome extends Enum {
  translationKey = 'encounterMethodOutcome';

  static asArray: EncounterMethodOutcome[] = [];

  static byKey: { [name: string]: EncounterMethodOutcome } = {};

  static readonly SUCCESSFUL = new EncounterMethodOutcome('SUCCESSFUL');

  static readonly LEFT_MESSAGE = new EncounterMethodOutcome('LEFT_MESSAGE');

  static readonly UNABLE_TO_LEAVE_MESSAGE = new EncounterMethodOutcome(
    'UNABLE_TO_LEAVE_MESSAGE',
  );

  static readonly PHONE_NUMBER_INVALID = new EncounterMethodOutcome(
    'PHONE_NUMBER_INVALID',
  );

  static readonly SENT = new EncounterMethodOutcome('SENT');

  static readonly CHAT_SUCCESSFUL = new EncounterMethodOutcome(
    'CHAT_SUCCESSFUL',
  );

  static readonly NO_RESPONSE = new EncounterMethodOutcome('NO_RESPONSE');

  static readonly UNABLE_TO_CONNECT = new EncounterMethodOutcome(
    'UNABLE_TO_CONNECT',
  );

  static readonly INVALID_EMAIL = new EncounterMethodOutcome('INVALID_EMAIL');

  static readonly INVALID_FAX_NUMBER = new EncounterMethodOutcome(
    'INVALID_FAX_NUMBER',
  );

  static readonly NO_SHOW = new EncounterMethodOutcome('NO_SHOW');

  static readonly RETURNED_UNDELIVERABLE = new EncounterMethodOutcome(
    'RETURNED_UNDELIVERABLE',
  );

  static readonly NO_ANSWER_NO_VOICEMAIL = new EncounterMethodOutcome(
    'NO_ANSWER_NO_VOICEMAIL',
  );

  static readonly TRANSFERRED = new EncounterMethodOutcome('TRANSFERRED');

  static readonly ESCALATED = new EncounterMethodOutcome('ESCALATED');

  static toSelectable() {
    return Enum.toSelectable(EncounterMethodOutcome.asArray);
  }

  constructor(public readonly value: string) {
    super();
    EncounterMethodOutcome.asArray.push(this);
    EncounterMethodOutcome.byKey[value] = this;
  }

  static getColor(status: EncounterMethodOutcome) {
    switch (status) {
      case EncounterMethodOutcome.SUCCESSFUL:
        return 'success';
      case EncounterMethodOutcome.INVALID_EMAIL:
      case EncounterMethodOutcome.INVALID_FAX_NUMBER:
      case EncounterMethodOutcome.PHONE_NUMBER_INVALID:
      case EncounterMethodOutcome.UNABLE_TO_CONNECT:
      case undefined:
        return 'error';
      default:
        return 'warning';
    }
  }
}

export default EncounterMethodOutcome;
