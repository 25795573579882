import { Employee } from './Employee';
import { make } from './Model';

export class PodMetricsSummaryEmployee {
  id = 0;

  role = '';

  employee: Employee;

  allocatedMembers = 0;

  maxMembers = 0;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.employee = make(obj.employee, Employee);
  }
}

export default PodMetricsSummaryEmployee;
