import { Moment } from 'moment';

import { make, makeArray, makeUnix } from '../Model';
import { PodReportingAherenceData } from '../PodReportingAherenceData';
import { PodReportingData } from '../PodReportingData';
import { ProgramExtensionStatus } from '../ProgramExtensionStatus';
import { PodReportingRPMSummary } from './PodReportingRPMSummary';

export class PodReportingRPM {
  adherence: PodReportingAherenceData[];

  lastUpdatedAt?: Moment;

  status: PodReportingData[];

  summary: PodReportingRPMSummary;

  constructor(obj: any) {
    Object.assign(this, obj);
    const adherenceTotalCount = obj.adherence.reduce(
      (acc: number, { members }: { members: number }) => acc + members,
      0,
    );
    this.adherence = [
      ...makeArray(
        obj.adherence.map((item: any) => ({
          percentage: Math.round((item.members / adherenceTotalCount) * 100),
          ...item,
        })),
        PodReportingAherenceData,
      ),
      new PodReportingAherenceData({
        rpmMinutes: obj.adherence.reduce(
          (acc: number, { rpmMinutes }: { rpmMinutes: number }) =>
            acc + rpmMinutes,
          0,
        ),
        members: adherenceTotalCount,
      }),
    ];
    this.lastUpdatedAt = makeUnix(obj.lastUpdatedAt);
    this.status = makeArray(
      obj.status?.filter(
        ({ name }: { name: string }) =>
          ![
            ProgramExtensionStatus.ACTIVE,
            ProgramExtensionStatus.NOT_STARTED,
            ProgramExtensionStatus.IN_PROGRESS,
            ProgramExtensionStatus.READY_FOR_OUTREACH,
            ProgramExtensionStatus.DENIED_DECLINED,
          ].includes(name),
      ),
      PodReportingData,
    );
    this.summary = make(obj.summary, PodReportingRPMSummary);
  }
}

export default PodReportingRPM;
