import { Enum } from './Enum';

export class EncounterEscalatedReason extends Enum {
  translationKey = 'encounterEscalatedReason';

  static asArray: EncounterEscalatedReason[] = [];

  static byKey: { [name: string]: EncounterEscalatedReason } = {};

  static readonly HOSPITALIZATION_INPATIENT = new EncounterEscalatedReason(
    'HOSPITALIZATION_INPATIENT',
  );

  static readonly DECEASED = new EncounterEscalatedReason('DECEASED');

  static readonly PHONE_NUMBER_INCORRECT = new EncounterEscalatedReason(
    'PHONE_NUMBER_INCORRECT',
  );

  static readonly CHURN_REQUEST = new EncounterEscalatedReason('CHURN_REQUEST');

  static readonly DISSATISFIED_MEMBER = new EncounterEscalatedReason(
    'DISSATISFIED_MEMBER',
  );

  static readonly OTHER = new EncounterEscalatedReason('OTHER');

  static toSelectable() {
    return Enum.toSelectable(EncounterEscalatedReason.asArray);
  }

  constructor(public readonly value: string) {
    super();
    EncounterEscalatedReason.asArray.push(this);
    EncounterEscalatedReason.byKey[value] = this;
  }
}

export default EncounterEscalatedReason;
