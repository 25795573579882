import { makeStyles } from '@mui/styles';

import { BackgroundColors, Colors, Fonts } from 'styleguide-v2';

export const PodReportingStyles = makeStyles({
  borderBottom: {
    borderBottom: `1px solid ${BackgroundColors.lightGray}`,
  },
  borderContainer: {
    gap: 0,
    '& > div': {
      padding: '2rem 3.2rem',
      borderBottom: `1px solid ${BackgroundColors.lightGray}`,
      borderRight: `1px solid ${BackgroundColors.lightGray}`,
    },
  },
  chip: {
    '&&': {
      alignItems: 'center',
      border: `1px solid ${Colors.gray}AA`,
      display: 'flex',
      justifyContent: 'center',
      height: '3rem',
      marginRight: '1rem',
      padding: '0.25rem',
      width: '3rem',
    },
  },
  errorRow: {
    '&&': {
      color: Colors.textRed,
      fontWeight: 500,
    },
  },
  paddingContainer: {
    padding: '2rem 3.2rem',
  },
  subtitle: {
    '&&': {
      fontFamily: Fonts.fontFamily,
      fontSize: `calc(${Fonts.fontSize} * 1.25)`,
      fontWeight: 400,
      gap: '0.5rem',
      flexWrap: 'wrap',

      '&& > *': {
        minWidth: 'max-content',
      },
    },
  },
  subsubtitle: {
    '&&': {
      color: Colors.iconGray,
      fontFamily: Fonts.fontFamily,
      fontSize: `calc(${Fonts.fontSize} * 0.875)`,
      fontWeight: 400,
    },
  },
});
