import { EncounterMethodOutcome } from './EncounterMethodOutcome';
import { Enum } from './Enum';

export class EncounterMethod extends Enum {
  translationKey = 'encounterMethod';

  static asArray: EncounterMethod[] = [];

  static byKey: { [name: string]: EncounterMethod } = {};

  static readonly CHAT = new EncounterMethod('CHAT');

  static readonly SMS = new EncounterMethod('SMS');

  static readonly EMAIL = new EncounterMethod('EMAIL');

  static readonly CALL = new EncounterMethod('CALL');

  static readonly FAX = new EncounterMethod('FAX');

  static readonly VIDEO = new EncounterMethod('VIDEO');

  static readonly MAILING = new EncounterMethod('MAILING');

  static toSelectable() {
    return Enum.toSelectable(EncounterMethod.asArray);
  }

  constructor(public readonly value: string) {
    super();
    EncounterMethod.asArray.push(this);
    EncounterMethod.byKey[value] = this;
  }

  static getEncounterOutomes(method?: EncounterMethod) {
    if (method === EncounterMethod.CALL) {
      return [
        EncounterMethodOutcome.SUCCESSFUL,
        EncounterMethodOutcome.LEFT_MESSAGE,
        EncounterMethodOutcome.UNABLE_TO_LEAVE_MESSAGE,
        EncounterMethodOutcome.NO_ANSWER_NO_VOICEMAIL,
        EncounterMethodOutcome.PHONE_NUMBER_INVALID,
        EncounterMethodOutcome.ESCALATED,
        EncounterMethodOutcome.TRANSFERRED,
      ];
    }
    if (method === EncounterMethod.CHAT) {
      return [
        EncounterMethodOutcome.SENT,
        EncounterMethodOutcome.CHAT_SUCCESSFUL,
        EncounterMethodOutcome.NO_RESPONSE,
      ];
    }
    if (method === EncounterMethod.SMS) {
      return [EncounterMethodOutcome.SENT, EncounterMethodOutcome.NO_RESPONSE];
    }
    if (method === EncounterMethod.EMAIL) {
      return [
        EncounterMethodOutcome.SENT,
        EncounterMethodOutcome.RETURNED_UNDELIVERABLE,
      ];
    }
    if (method === EncounterMethod.FAX) {
      return [
        EncounterMethodOutcome.SENT,
        EncounterMethodOutcome.INVALID_FAX_NUMBER,
      ];
    }
    if (method === EncounterMethod.VIDEO) {
      return [
        EncounterMethodOutcome.SUCCESSFUL,
        EncounterMethodOutcome.UNABLE_TO_CONNECT,
        EncounterMethodOutcome.UNABLE_TO_LEAVE_MESSAGE,
        EncounterMethodOutcome.NO_SHOW,
      ];
    }
    if (method === EncounterMethod.MAILING) {
      return [
        EncounterMethodOutcome.SENT,
        EncounterMethodOutcome.INVALID_EMAIL,
        EncounterMethodOutcome.RETURNED_UNDELIVERABLE,
      ];
    }

    return [];
  }
}

export default EncounterMethod;
