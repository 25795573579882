export class PodReportingRPMSummary {
  active = 0;

  activeMembers = 1;

  inProgress = 0;

  notStarted = 0;

  readyForOutreach = 0;

  total = 0;

  other = 0;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export default PodReportingRPMSummary;
