export class PodReportingOverviewSummary {
  accepted = 0;

  active = 0;

  deceased = 0;

  disenrolled = 0;

  passive = 0;

  total = 0;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export default PodReportingOverviewSummary;
