import { PodMetricsSummaryData } from './PodMetricsSummaryData';

export class PodMetricsSummaryAllocation {
  type = '';

  allocation = 0;

  capacity = 0;

  constructor(type: string, allocation: any[], capacity: any[]) {
    this.type = type;
    this.allocation =
      (allocation as PodMetricsSummaryData[]).find(
        ({ item }) => item === this.type,
      )?.count || 0;
    this.capacity =
      (capacity as PodMetricsSummaryData[]).find(
        ({ item }) => item === this.type,
      )?.count || 0;
  }

  get capacityPerc() {
    return Math.round(this.allocation / this.capacity);
  }

  static sort(
    { type: a }: PodMetricsSummaryAllocation,
    { type: b }: PodMetricsSummaryAllocation,
  ) {
    return a.localeCompare(b);
  }
}

export default PodMetricsSummaryAllocation;
