import { RPMAdherenceLevel } from '../enums';

export class PodReportingAherenceData {
  id = 0;

  rpmAdherence?: RPMAdherenceLevel;

  members = 0;

  rpmMinutes = 0;

  percentage = 0;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.rpmAdherence =
      obj.rpmAdherence && RPMAdherenceLevel.byKey[obj.rpmAdherence];
  }
}

export default PodReportingAherenceData;
