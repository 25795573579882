import { Moment } from 'moment';

import { translate } from '../../i18n';
import { make, makeArray, makeUnix } from '../Model';
import { PodReportingDataExtended } from '../PodReportingDataExtended';
import { PodReportingOverviewSummary } from './PodReportingOverviewSummary';

const makeBiannual = (obj: any) => [
  new PodReportingDataExtended({
    name: translate('podReporting.table.due'),
    total: obj.npBiannualDueCount || 0,
    subtotals: [
      {
        name: translate('podReporting.table.completed'),
        total: obj.npBiannualCompletedCount || 0,
        __order: 1,
      },
      {
        name: translate('podReporting.table.pending'),
        total: obj.npBiannualPendingCount || 0,
        __order: 2,
      },
      {
        name: translate('podReporting.table.rescheduleNeeded'),
        total: obj.npBiannualRescheduleDueCount || 0,
        __order: 3,
      },
      {
        name: translate('podReporting.table.notYetScheduled'),
        total:
          (obj.npBiannualNotYetDueReachableCount || 0) +
          (obj.npBiannualNotYetDueUnreachableCount || 0),
        __order: 4,
      },
    ],
  }),
  new PodReportingDataExtended({
    name: translate('podReporting.table.overdue'),
    total: obj.npBiannualOverdueCount,
    subtotals: [
      {
        name: translate('podReporting.table.rescheduleNeeded'),
        total: obj.npBiannualRescheduleOverdueCount || 0,
        __order: 1,
      },
      {
        name: translate('podReporting.table.notYetScheduled'),
        total:
          (obj.npBiannualNotYetOverDueReachableCount || 0) +
          (obj.npBiannualNotYetOverDueUnreachableCount || 0),
        __order: 2,
      },
    ],
  }),
];

export class PodReportingOverview {
  biannual: PodReportingDataExtended[];

  lastUpdatedAt?: Moment;

  programs: PodReportingDataExtended[];

  summary: PodReportingOverviewSummary;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.biannual = makeBiannual(obj.visits) || [];
    this.lastUpdatedAt = makeUnix(obj.lastUpdatedAt);
    this.programs = makeArray(obj.programs, PodReportingDataExtended) || [];
    this.summary = make(obj.summary, PodReportingOverviewSummary) || [];
  }
}

export default PodReportingOverview;
