import classNames from 'classnames';
import React from 'react';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { PodReportingAherenceData } from '@vestahealthcare/common/models';

import { Fonts, Table } from 'styleguide-v2';
import { Column } from 'styleguide-v2/src/components/Table';

interface Props {
  boldLastRow?: boolean;
  className?: string;
  data: PodReportingAherenceData[];
  header: string;
  onClick?: (data: PodReportingAherenceData, idx: number) => void;
}

const POD_REPORTING_DATA_COLUMNS = ({
  boldLastRow,
  data,
  header,
  onClick,
}: Props) => {
  const columns = [
    {
      headerName: header,
      component: (
        { rpmAdherence }: PodReportingAherenceData,
        index: number,
      ) => (
        <span
          style={{
            fontWeight: boldLastRow && index === data?.length - 1 ? 500 : 400,
          }}
        >
          {rpmAdherence?.toString() ||
            (index === data.length - 1
              ? translate('podReporting.table.total')
              : translate('podReporting.table.noReadings'))}
        </span>
      ),
    },
    {
      headerName: `${translate('podReporting.table.total')} (%)`,
      component: (
        { members, percentage }: PodReportingAherenceData,
        index: number,
      ) => (
        <div
          className="flex gap space-between"
          style={{
            fontWeight: boldLastRow && index === data?.length - 1 ? 500 : 400,
            paddingRight: '1rem',
          }}
        >
          <span>{members}</span>
          <span>
            {index !== data.length - 1 ? `(${percentage}%)` : '(100%)'}
          </span>
        </div>
      ),
      width: 100,
    },
    {
      headerName: translate('podReporting.table.minutes'),
      component: ({ rpmMinutes }: PodReportingAherenceData, index: number) => (
        <span
          style={{
            fontWeight: boldLastRow && index === data?.length - 1 ? 500 : 400,
          }}
        >
          {rpmMinutes} {translate('common.minutes')}
        </span>
      ),
      width: 120,
    },
  ];
  const clickableColumn = {
    headerName: '',
    component: (_: PodReportingAherenceData, idx: number) =>
      idx < data.length - 1 ? (
        <ArrowForwardIosIcon color="action" fontSize="small" />
      ) : (
        <></>
      ),
    width: 20,
  };

  return [...columns, onClick ? clickableColumn : undefined].filter(
    Boolean,
  ) as Column[];
};

const useStyles = makeStyles({
  table: {
    '&& tbody': {
      '& td': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      },
      '& a': {
        fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      },
    },
  },
});

export const PodReportingAdherenceTable = (props: Props) => {
  const styles = useStyles();

  return (
    <Table
      className={classNames(props.className, styles.table)}
      config={{
        columns: POD_REPORTING_DATA_COLUMNS(props),
        compact: true,
        data: props.data,
        paginationOptions: [-1],
      }}
      onClickRow={
        props.onClick
          ? (data: PodReportingAherenceData, idx: number) =>
              idx !== props.data.length - 1 &&
              props.onClick &&
              props.onClick(data, idx)
          : undefined
      }
      whiteBackground
    />
  );
};

export default PodReportingAdherenceTable;
