import React from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { PodMetricsSummary } from '@vestahealthcare/common/models';

import { PieChartMultiLevel } from 'styleguide-v2';

import { COLUMN_SIZE } from './constants';

type Props = {
  data: PodMetricsSummary[];
};

const useStyles = makeStyles({
  chart: {
    margin: '1rem auto',
  },
  container: {
    display: 'flex',
  },
  itemContainer: {
    width: `${COLUMN_SIZE}px`,
  },
});

export const PODSummaryTableBiannual = ({ data }: Props) => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      {data.map((pod, idx) => (
        <div className={styles.itemContainer} key={`pod-program-${idx}`}>
          <PieChartMultiLevel
            className={styles.chart}
            data={[
              {
                id: `pod-${pod.id}-biannual-status-completed`,
                label: translate('common.status.completed'),
                value: pod.npBiannualCompletedCount,
                subdata: [],
              },
              {
                id: `pod-${pod.id}-biannual-status-pending`,
                label: translate('common.status.pending'),
                value: pod.npBiannualPendingCount,
                subdata: [],
              },
              {
                id: 'status-scheduled',
                label: 'Reschedule Needed',
                value:
                  pod.npBiannualRescheduleDueCount +
                  pod.npBiannualRescheduleOverdueCount,
                subdata: [
                  {
                    id: 'status-scheduled-1',
                    label: 'Due this month',
                    value: pod.npBiannualRescheduleDueCount,
                  },
                  {
                    id: 'status-scheduled-2',
                    label: 'Overdue',
                    value: pod.npBiannualRescheduleOverdueCount,
                  },
                ],
              },
              {
                id: 'status-not',
                label: 'Not yet scheduled',
                value:
                  pod.npBiannualNotYetDueUnreachableCount +
                  pod.npBiannualNotYetDueReachableCount +
                  pod.npBiannualNotYetOverdueUnreachableCount +
                  pod.npBiannualNotYetOverdueReachableCount,
                subdata: [
                  {
                    id: 'status-scheduled-1',
                    label: 'Not yet scheduled this month',
                    value:
                      pod.npBiannualNotYetDueUnreachableCount +
                      pod.npBiannualNotYetDueReachableCount,
                  },
                  {
                    id: 'status-scheduled-2',
                    label: 'Not yet scheduled overdue',
                    value:
                      pod.npBiannualNotYetOverdueUnreachableCount +
                      pod.npBiannualNotYetOverdueReachableCount,
                  },
                ],
              },
            ]}
            getTooltipValue={(value, _, percentage) =>
              `${value} (${percentage}%)`
            }
            size="s"
          />
        </div>
      ))}
    </div>
  );
};
