// @ts-ignore
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import DownloadIcon from '@mui/icons-material/Download';
import { makeStyles } from '@mui/styles';

import {
  CollectionMethod,
  Language,
  MemberContactMethodType,
  MemberStatus,
  RiskLevel,
  States,
} from '@vestahealthcare/common/enums';
import Enum, {
  Selectable,
  SelectableInfo,
} from '@vestahealthcare/common/enums/Enum';
import { translate } from '@vestahealthcare/common/i18n';
import {
  BaseEnum,
  Employee,
  MemberView,
  Organization,
  PaginationType,
  ProgramExtensionStatus,
  StoredFilter,
} from '@vestahealthcare/common/models';
import { LS_MEMBER_FILTERS_V2 } from '@vestahealthcare/common/utils/constants';

import {
  CheckboxWithLabel,
  FilterItem,
  IconButton,
  Panel,
} from 'styleguide-v2';

import { showGlobalError } from 'dash/src/components/GlobalMessage';
import { MemberViewFiltersModal } from 'dash/src/components/MemberViewFilters/MemberViewFiltersModal';
import {
  onStoredFilterAdd,
  onStoredFilterChange,
  onStoredFilterDeleted,
} from 'dash/src/redux/slices/EmployeeSlice';
import { useSelector } from 'dash/src/redux/store';
import { CacheServices } from 'dash/src/services';
import {
  createSelfStoredFilter,
  deleteSelfStoredFilter,
  updateSelfStoredFilter,
} from 'dash/src/services/EmployeeServices';
import {
  MemberViewParams,
  downloadMemberExport,
  getMembersView,
} from 'dash/src/services/PatientServices';
import Session from 'dash/src/services/SessionServices';
import {
  LoadFilterOption,
  getServerFilters,
  loadNewFilters,
  loadNewFiltersFromStorage,
  saveNewFilters,
  saveNewFiltersToStorage,
} from 'dash/src/utils/filterUtils';
import { useQueryParams } from 'dash/src/utils/useQueryParams';

import { MemberViewFiltersBar } from '../../../components/MemberViewFilters/MemberViewFiltersBar';
import { MembersDataTable } from './MembersDataTable';

const useStyles = makeStyles({
  checkbox: {
    '&& > div > label': {
      display: 'flex',
      flexFlow: 'row-reverse',
      margin: 0,
    },
  },
});

const UNASSIGNED = { label: translate('global.unassigned'), value: 0 };

const MEMBERS_PAGE_FILTER = 'members-page';

type KeyGetMemberParams = keyof MemberViewParams;

export const MembersDashboard = () => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const query = useQueryParams();

  const storedFilters = useSelector((state) =>
    state.employeeSlice.storedFilters?.filter(
      StoredFilter.filter(MEMBERS_PAGE_FILTER),
    ),
  );

  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [members, setMembers] = useState<MemberView[]>([]);
  const [pagination, setPagination] = useState<PaginationType>();
  const [newFilters, setNewFilters] = useState<
    {
      [x in KeyGetMemberParams]?: FilterItem;
    }
  >();

  const [downloadingStatus, setDownloadingStatus] = useState<boolean>();
  const [downloadExtendedReport, setDownloadExtendedReport] = useState<boolean>(
    false,
  );

  const [languages, setLanguages] = useState<Selectable[]>([]);
  const [employees, setEmployees] = useState<SelectableInfo<Employee>[]>([]);
  const [carePlanGroups, setCarePlanGroups] = useState<Selectable[]>([]);
  const [programExtensions, setProgramExtensions] = useState<Selectable[]>([]);
  const [programExtensionStatuses, setProgramExtensionStatuses] = useState<
    Selectable[]
  >([]);
  const [referralSources, setReferralSources] = useState<
    SelectableInfo<Organization>[]
  >([]);
  const [reporterStatuses, setReporterStatuses] = useState<BaseEnum[]>([]);
  const [brands, setBrands] = useState<Selectable[]>([]);

  const { actingUser } = Session;
  const showDownloadExport = actingUser.canExportMembers;

  const mapEmployees = (array: Employee[]) =>
    Employee.toSelectable(array.sort(Employee.sort));

  const fetchFiltersData = async () => {
    setLoadingFilters(true);
    const [l, cpgs, employees] = await Promise.all([
      CacheServices.getLanguages(),
      CacheServices.getCarePlanGroups(),
      CacheServices.getEmployees(),
    ]);

    const [pe, pes, o, rs, b] = await Promise.all([
      CacheServices.getAllProgramExtensions(),
      CacheServices.getProgramExtensionStatus(),
      CacheServices.getOrganizations(),
      CacheServices.getCareTeamPersonStatuses(),
      CacheServices.getOrganizationBrands(),
    ]);
    setLanguages(Language.toSelectable(l).filter(({ disabled }) => !disabled));
    setCarePlanGroups([
      UNASSIGNED,
      ...cpgs.map(({ id: value, description: label }) => ({
        value,
        label,
      })),
    ]);
    const referralsOptions = Organization.toSelectable(o);

    setEmployees([UNASSIGNED, ...mapEmployees(employees)]);
    setProgramExtensions(
      pe.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    );
    setProgramExtensionStatuses(ProgramExtensionStatus.toSelectable(pes));
    setReferralSources(referralsOptions);
    setReporterStatuses(rs);
    setBrands(
      b.map(({ id, name }) => ({ value: id, label: name } as Selectable)),
    );
    setLoadingFilters(false);
  };

  useEffect(() => {
    fetchFiltersData();
  }, []);

  const fetchPatients = async () => {
    let currentFilters: MemberViewParams;

    setLoading(true);
    try {
      if (!newFilters) return;
      currentFilters = getServerFilters(newFilters);

      const { items, pagination } = await getMembersView({
        ...currentFilters,
        offset: page * pageSize,
        limit: pageSize,
        sort: 'fullName asc, id asc',
      });
      setPagination(pagination);
      if (page !== 0) {
        setMembers([...members, ...items]);
      } else {
        setMembers(items);
      }
    } catch (e) {
      showGlobalError(e as string);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPatients();
  }, [newFilters, page, pageSize]);

  useEffect(() => {
    if (newFilters) {
      saveNewFiltersToStorage(LS_MEMBER_FILTERS_V2, newFilters);
    }
  }, [newFilters]);

  const storedFiltersConfiguration = useMemo(() => {
    if (brands?.length && employees?.length) {
      return {
        brandId: {
          data: brands,
          label: translate('members.brand'),
          multiple: true,
        },
        careCoordinatorId: {
          data: employees,
          label: translate('members.clinicalAssistant'),
          multiple: true,
        },
        excludedReferralSourceId: {
          data: referralSources,
          label: translate('members.excludedReferralSources'),
          multiple: true,
        },
        engagementOwnerId: {
          data: employees,
          label: translate('members.engagementOwners'),
          multiple: true,
        },
        hasCareTeam: {
          label: translate('members.hasCareTeam'),
        },
        hciReporter: {
          label: translate('members.selfReporter'),
        },
        hciReporterStatus: {
          data: reporterStatuses,
          label: translate('members.reporterStatus'),
          multiple: true,
        },
        hciReporterMethod: {
          data: Enum.toSelectable(MemberContactMethodType.asArray),
          label: translate('members.reportingPreference'),
          multiple: true,
        },
        healthCoachId: {
          data: employees,
          label: translate('members.healthCoach'),
          multiple: true,
        },
        language: {
          data: languages,
          label: translate('members.language'),
          multiple: true,
        },
        id: { label: translate('members.id') },
        fullName: { label: translate('members.name') },
        npOwnerId: {
          data: employees,
          label: translate('members.npOwner'),
          multiple: true,
        },
        podManagerId: {
          data: employees,
          label: translate('members.podManager'),
          multiple: true,
        },
        programExtensionId: {
          data: programExtensions,
          label: translate('members.programExtensions'),
          multiple: true,
        },
        programExtensionStatus: {
          data: programExtensionStatuses,
          label: translate('members.programExtensionStatus'),
          multiple: true,
        },
        referralSource: {
          data: referralSources,
          label: translate('members.referralSources'),
          multiple: true,
        },
        riskLevel: {
          data: Enum.toSelectable(RiskLevel.asArray),
          label: translate('members.riskLevel'),
          multiple: true,
        },
        rnOwnerId: {
          data: employees,
          label: translate('members.rnOwner'),
          multiple: true,
        },
        vitalCollectionMethod: {
          data: CollectionMethod.toVendorSelectable(),
          label: translate('members.vitalsCollectionMethod'),
          multiple: true,
        },
        state: {
          data: Enum.toSelectable(States.asArray),
          label: translate('members.state'),
          multiple: true,
        },
        status: {
          data: Enum.toSelectable(MemberStatus.asArray),
          label: translate('members.status'),
          multiple: true,
        },
        worklistGroupId: {
          data: carePlanGroups,
          label: translate('members.carePlanGroup'),
          multiple: true,
        },
      } as Partial<
        {
          [x in KeyGetMemberParams]: LoadFilterOption;
        }
      >;
    }
  }, [
    brands,
    carePlanGroups,
    employees,
    languages,
    programExtensions,
    referralSources,
    reporterStatuses,
  ]);

  useEffect(() => {
    if (storedFiltersConfiguration) {
      const clearFilters = query.get('clearFilters');

      const storedFilters = loadNewFiltersFromStorage(
        clearFilters ? '' : LS_MEMBER_FILTERS_V2,
        storedFiltersConfiguration,
        query,
      );
      setNewFilters(storedFilters);
    }
  }, [storedFiltersConfiguration]);

  return (
    <Panel id="referrals-section">
      <Panel.Heading title={translate('members.title')} filtersV2>
        <Panel.FilterBar
          onClearFilters={() => {
            setNewFilters({});
          }}
          onDeleteFilter={(key: string) => {
            setNewFilters({
              ...newFilters,
              [key]: undefined,
            });
          }}
          onOpenFilters={() => setOpen(!isOpen)}
          onSelectFilter={(filter) =>
            storedFiltersConfiguration &&
            setNewFilters(
              loadNewFilters(filter.filters, storedFiltersConfiguration),
            )
          }
          chips={newFilters}
          inputs={
            <MemberViewFiltersBar
              data={{
                employees,
                languages,
                referralSources,
                programExtensions,
                programExtensionStatuses,
              }}
              loading={loadingFilters}
              filters={newFilters}
              onChange={(filters) => {
                setPage(0);
                setNewFilters(filters);
              }}
            />
          }
          storedFilters={storedFilters}
        />
        {showDownloadExport && (
          <Panel.Actions>
            <CheckboxWithLabel
              className={styles.checkbox}
              label={translate('members.extendedReport')}
              checked={downloadExtendedReport || false}
              onChange={() => {
                setDownloadExtendedReport(!downloadExtendedReport);
              }}
            />
            <IconButton
              data-cy="member-export"
              tooltip={translate('members.downloadMembersFile')}
              loading={downloadingStatus}
              onClick={async () => {
                setDownloadingStatus(true);
                if (!newFilters) return;
                const currentFilters = getServerFilters(newFilters);

                await downloadMemberExport(
                  currentFilters,
                  downloadExtendedReport,
                );
                setDownloadingStatus(false);
              }}
            >
              <DownloadIcon />
            </IconButton>
          </Panel.Actions>
        )}
      </Panel.Heading>
      <Panel.Body loading={loading}>
        <MemberViewFiltersModal
          data={{
            brands,
            employees,
            hciReporterStatuses: BaseEnum.toSelectable(reporterStatuses),
            languages,
            programExtensions,
            programExtensionStatuses,
            referralSources,
            statuses: [],
            worklistGroups: carePlanGroups,
          }}
          filters={newFilters}
          loadingFilters={loadingFilters}
          open={isOpen}
          onChange={(filters) => {
            setPage(0);
            setNewFilters(filters);
          }}
          onClose={() => setOpen(false)}
          onDeleteFilter={async (filter) => {
            await deleteSelfStoredFilter(filter.id);
            dispatch(onStoredFilterDeleted(filter));
          }}
          onEditFilter={async (filter) => {
            await updateSelfStoredFilter(filter.id, filter);
            dispatch(onStoredFilterChange(filter));
          }}
          onSaveFilter={async (filter) => {
            if (filter.id) {
              await updateSelfStoredFilter(filter.id, {
                filters: saveNewFilters(newFilters || {}),
              });
              dispatch(onStoredFilterChange(filter));
            } else if (newFilters) {
              const updated = await createSelfStoredFilter({
                description: filter.description,
                filters: saveNewFilters(newFilters || {}),
                page: MEMBERS_PAGE_FILTER,
                preferred: false,
              });
              dispatch(onStoredFilterAdd(updated));
            }
          }}
          storedFilters={storedFilters}
        />
        <MembersDataTable
          members={members}
          pagination={pagination}
          onChangePage={setPage}
          onChangePageSize={setPageSize}
        />
      </Panel.Body>
    </Panel>
  );
};

export default MembersDashboard;
