import { Enum } from './Enum';

export class PodRuleConfirmationType extends Enum {
  translationKey = 'podRuleConfirmationType';

  static asArray: PodRuleConfirmationType[] = [];

  static byKey: { [name: string]: PodRuleConfirmationType } = {};

  static readonly VISIT_TYPE = new PodRuleConfirmationType('VISIT_TYPE');

  static readonly CCM_TIMER_LOG = new PodRuleConfirmationType('CCM_TIMER_LOG');

  constructor(public readonly value: string) {
    super();
    PodRuleConfirmationType.asArray.push(this);
    PodRuleConfirmationType.byKey[value] = this;
  }
}

export default PodRuleConfirmationType;
