import classNames from 'classnames';
import React, { useState } from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import { PodMetricsSummary } from '@vestahealthcare/common/models';

import { Chip, Collapse, CollapseIcon, IconButton } from 'styleguide-v2';

import { getTaskColor } from '../utils';
import { PodSummaryCardTaskTable } from './PodSummaryCardTaskTable';
import { PODSummaryCardStyles } from './styles';

type Props = {
  className?: string;
  pod: PodMetricsSummary;
};

export const PodSummaryCardTask = ({ className, pod }: Props) => {
  const styles = PODSummaryCardStyles();
  const [openDetail, setOpenDetail] = useState<boolean>(false);

  return (
    <div className={className}>
      <div
        className={classNames(styles.itemContainer, 'clickable')}
        onClick={() => setOpenDetail(!openDetail)}
      >
        <span className={styles.subtitle}>
          {translate('pods.metrics.card.taskSummary')}
        </span>
        <div className={styles.titleInfo}>
          <Chip
            color={getTaskColor(pod.taskCompletedPercetage)}
            type="contained-2"
          >
            <span className="bold">{pod.taskCompletedPercetage}%</span>
          </Chip>
          <IconButton
            className={styles.titleInfoCollapse}
            onClick={() => setOpenDetail(!openDetail)}
            size="small"
          >
            <CollapseIcon open={openDetail} />
          </IconButton>
        </div>
      </div>
      <Collapse open={openDetail}>
        <div className={classNames(styles.itemContainer, 'flex')}>
          <PodSummaryCardTaskTable tasks={pod.taskStatusCounts} />
        </div>
      </Collapse>
    </div>
  );
};
