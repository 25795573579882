import { Enum } from '../enums';

export class PodMetricsSummaryData {
  item?: Enum | string;

  count = 0;

  constructor(obj: any) {
    Object.assign(this, obj);
  }

  static sort(
    { count: a }: PodMetricsSummaryData,
    { count: b }: PodMetricsSummaryData,
  ) {
    return b - a;
  }
}

export default PodMetricsSummaryData;
