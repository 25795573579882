import { make } from './Model';
import { PodReportingSummary } from './PodReportingSummary';

export class PodReportingGroup {
  summary: PodReportingSummary;

  worklistId = 0;

  worklistName = '';

  constructor(obj: any) {
    this.summary = make(obj.summary, PodReportingSummary);
    Object.assign(this, obj);
  }

  static sort(
    { worklistName: a, worklistId: aId }: PodReportingGroup,
    { worklistName: b, worklistId: bId }: PodReportingGroup,
  ) {
    if (!aId) return -1;
    if (!bId) return 1;
    return a.localeCompare(b);
  }
}

export default PodReportingGroup;
