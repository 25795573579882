import moment, { Moment } from 'moment';

import {
  CCMTask,
  PodConfig,
  PodMetricsSummary,
  PodMetricsSummaryEmployee,
  PodRule,
  PodRuleTrigger,
} from '@vestahealthcare/common/models';
import { DATE_FORMAT } from '@vestahealthcare/common/utils/constants';

import Api, {
  PaginatedResponse,
  PaginatedResponseWithTime,
  PaginationParams,
} from 'dash/src/services/Api';

export type GetPodConfigParams = {
  active?: boolean;
} & PaginationParams;

export const fetchPodConfigs = async (
  params?: GetPodConfigParams,
): Promise<PaginatedResponse<PodConfig>> => {
  const {
    pods: { items, pagination },
  } = await Api.getv2('pods', params);
  return {
    items: items.map((item: any) => new PodConfig(item)),
    pagination,
  };
};

export type PodConfigEmployeeSlot = {
  employeeId?: number;
  id?: number;
  maxMembers?: number;
  role: string;
};

export type CreatePodConfigParams = {
  employees: PodConfigEmployeeSlot[];
  languages: string[];
  managerId: number;
  maxMembers?: number;
  name: string;
  programExtensionIds: number[];
  referralSourceIds?: number[];
  states: string[];
};

export const createPodConfig = async (params?: CreatePodConfigParams) =>
  await Api.postv2JSON('pods', params);

export type UpdatePodConfigParams = Partial<
  CreatePodConfigParams & {
    active: boolean;
  }
>;

export const updatePodConfig = async (
  id: number,
  { employees, ...params }: UpdatePodConfigParams,
) =>
  await Api.patchv2JSON(`pods/${id}`, {
    employees: employees?.map(({ id, employeeId, maxMembers, ...item }) => ({
      id: id || undefined,
      employeeId: employeeId || undefined,
      maxMembers: maxMembers || undefined,
      ...item,
    })),
    ...params,
  });

export type GetPodRuleParams = {
  active?: boolean;
} & PaginationParams;

export const fetchPodRules = async (
  params?: GetPodRuleParams,
): Promise<PaginatedResponse<PodRule>> => {
  const {
    taskDefinitions: { items, pagination },
  } = await Api.getv2('program-tasks/definitions', params);
  return {
    items: items.map((item: any) => new PodRule(item)),
    pagination,
  };
};

export const fetchPodTriggers = async (): Promise<PodRuleTrigger[]> => {
  const {
    triggers: { items },
  } = await Api.getv2('triggers', { sort: 'name asc' });
  return items.map((item: any) => new PodRuleTrigger(item));
};

export type CreatePodRuleParams = {
  additional: boolean;
  confirmationType?: string;
  confirmationTypeReason?: string;
  default: boolean;
  description?: string;
  flexible: boolean;
  maxAttempts: number;
  name: string;
  overriddenBy?: number[];
  programExtensionId: number;
  requireConfirmation: boolean;
  roles: string[];
  rolloverAfterPeriod: boolean;
  spacing?: number;
  triggers?: {
    triggerName: string;
    delayValue?: number;
    delayType?: string;
    status?: string;
  }[];
};

export type UpdatePodRuleParams = Partial<
  CreatePodRuleParams & {
    active: boolean;
  }
>;

export type GetCCMTaskParams = PaginationParams & {
  assigneeId?: number;
};

export const fetchCCMTasks = async (
  params: GetCCMTaskParams,
): Promise<PaginatedResponseWithTime<CCMTask>> => {
  const {
    ccmTasks: { items, pagination, lastUpdatedAt },
  } = await Api.getv2('program-tasks/ccm', params);
  return {
    items: items.map((x: any) => new CCMTask(x)),
    lastUpdatedAt: lastUpdatedAt ? moment.unix(lastUpdatedAt) : undefined,
    pagination,
  };
};

export type CompleteCCMTaskParams = {
  notes?: string;
};

export const completeCCMTask = async (
  id: number,
  params: CompleteCCMTaskParams,
) => {
  await Api.patchv2JSON(`program-tasks/${id}`, {
    status: 'COMPLETED',
    ...params,
  });
};

export type GetPodMetricsParams = {
  month?: Moment;
} & PaginationParams;

export const fetchPodMetricsSummary = async ({
  month,
  ...rest
}: GetPodMetricsParams): Promise<PodMetricsSummary[]> => {
  const isCurrentMonth = month?.isSame(moment(), 'month');
  if (isCurrentMonth) {
    const {
      podSummaries: { items },
    } = await Api.getv2('pods/summary', rest);
    return items.map((item: any) => new PodMetricsSummary(item));
  }
  const {
    podSummaries: { items },
  } = await Api.getv2('pods/summary/snapshot', {
    month: month?.format(DATE_FORMAT),
    ...rest,
  });
  return items.map((item: any) => new PodMetricsSummary(item));
};

export const fetchPodMetricEmployees = async (
  id: number,
): Promise<PodMetricsSummaryEmployee[]> => {
  const {
    podEmployees: { items },
  } = await Api.getv2(`pods/summary/${id}/employees`);
  return items.map((item: any) => new PodMetricsSummaryEmployee(item));
};

const prepareParams = ({
  additional,
  rolloverAfterPeriod,
  default: isDefault,
  flexible,
  ...params
}: CreatePodRuleParams | UpdatePodRuleParams) => ({
  isAdditional: additional,
  isRolloverAfterPeriod: rolloverAfterPeriod,
  isDefault,
  isFlexible: flexible,
  ...params,
});

export const createPodRule = async (params: CreatePodRuleParams) =>
  await Api.postv2JSON('program-tasks/definitions', prepareParams(params));

export const updatePodRule = async (id: number, params: UpdatePodRuleParams) =>
  await Api.patchv2JSON(
    `program-tasks/definitions/${id}`,
    prepareParams(params),
  );
