import React from 'react';

import { makeStyles } from '@mui/styles';

import { PodMetricsSummary } from '@vestahealthcare/common/models';
import PodMetricsSummaryAllocation from '@vestahealthcare/common/models/PodMetricsSummaryAllocation';

import { Chip, Tooltip } from 'styleguide-v2';

import { COLUMN_SIZE } from './constants';

type Props = {
  data: PodMetricsSummary[];
};

const useStyles = makeStyles({
  chart: {
    margin: '1rem auto',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  itemContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.5rem',
    justifyContent: 'center',
    padding: '2rem',
    width: `${COLUMN_SIZE}px`,
  },
});

export const PODSummaryTableAllocation = ({ data }: Props) => {
  const styles = useStyles();

  const getColor = (perc: number) => {
    if (perc < 25 || perc > 95) return 'error';
    if (perc < 75) return 'warning';
    return 'success';
  };

  const renderChip = (label: string, data: PodMetricsSummaryAllocation) => (
    <Tooltip text={`${data.allocation}/${data.capacity}`}>
      <div>
        <Chip
          className="semi-bold"
          color={getColor(data.capacityPerc)}
          type="contained-2"
        >
          {label}: {data.capacityPerc}%
        </Chip>
      </div>
    </Tooltip>
  );

  return (
    <div className={styles.container}>
      {data.map(
        (
          {
            npAllocation,
            rnAllocation,
            hcAllocation,
            cmaAllocation,
            engagementAllocation,
          },
          idx,
        ) => (
          <div
            className={styles.itemContainer}
            key={`pod-member-allocation-${idx}`}
          >
            {renderChip('NPs', npAllocation)}
            {renderChip('RNs', rnAllocation)}
            {renderChip('HCs', hcAllocation)}
            {renderChip('CMAs', cmaAllocation)}
            {renderChip('Engagement', engagementAllocation)}
          </div>
        ),
      )}
    </div>
  );
};
