import classNames from 'classnames';
import React from 'react';

import { translate } from '@vestahealthcare/common/i18n';
import {
  PodReportingData,
  PodReportingTOC,
  ProgramExtensionStatus,
} from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { Colors, Summary, Tooltip } from 'styleguide-v2';

import PodReportingDataTable from './PodReportingDataTable';
import { PodReportingProgramTable } from './PodReportingProgramTable';
import { PodReportingStyles } from './PodReportingStyles';

type Props = {
  data?: PodReportingTOC;

  onClickSkilled: () => void;
  onClickTOC: () => void;
  onClickTOCSkilled: () => void;
  onClickChaseList24?: () => void;
  onClickChaseList48?: (item: PodReportingData) => (() => void) | undefined;
  onClickStatus: (item: PodReportingData) => void;

  peStatuses: ProgramExtensionStatus[];
};

export const PodReportingTOCPage = ({
  data,
  onClickChaseList24,
  onClickChaseList48,
  peStatuses,
}: Props) => {
  const styles = PodReportingStyles();
  if (!data) return <></>;

  const { apptsDue, chaseList, chaseList24Count, status, summary } = data;

  return (
    <>
      <div
        className={classNames(
          'flex spaced',
          styles.borderBottom,
          styles.paddingContainer,
        )}
      >
        <Summary
          color={Colors.iconGreen}
          title={translate('podReporting.summary.tocSkilled')}
          body={summary?.tocSkilled !== undefined ? summary.tocSkilled : EMPTY}
          // footer={translate('podReporting.summary.seeDetails')}
          // onClick={onClickTOCSkilled}
        />
        <Summary
          color={Colors.mint}
          title={translate('podReporting.summary.toc')}
          body={summary?.toc !== undefined ? summary.toc : EMPTY}
          // footer={translate('podReporting.summary.seeDetails')}
          // onClick={onClickTOC}
        />
        <Summary
          color={Colors.mint}
          title={translate('podReporting.summary.skilled')}
          body={summary?.skilled !== undefined ? summary.skilled : EMPTY}
          // footer={translate('podReporting.summary.seeDetails')}
          // onClick={onClickSkilled}
        />
        <div style={{ width: 100 }} />
        <div style={{ width: 100 }} />
        <div style={{ width: 100 }} />
      </div>
      <div className={classNames('grid-wrapper', styles.borderContainer)}>
        <div className="grid-span-6">
          <div className="flex gap">
            <h2 className={styles.subtitle}>
              <span className={styles.subtitle}>
                {translate('podReporting.titleChaseList')}
              </span>
            </h2>
          </div>
          <PodReportingProgramTable
            data={chaseList}
            extraData={{
              '2 touchpoints needed': chaseList24Count ? (
                <Tooltip
                  text={translate('podReporting.tooltips.chaseList24Hours')}
                >
                  <div className="bold" onClick={onClickChaseList24}>
                    <span className="red">({chaseList24Count})</span>
                  </div>
                </Tooltip>
              ) : undefined,
            }}
            header={translate('podReporting.table.type')}
            getRowOnClick={onClickChaseList48}
          />
        </div>
        <div className="grid-span-6">
          <div className="flex gap">
            <h2 className={styles.subtitle}>
              <span className={styles.subtitle}>
                {translate('podReporting.titleApptsDue')}
              </span>
            </h2>
          </div>
          <PodReportingProgramTable
            data={apptsDue}
            header={translate('podReporting.table.type')}
          />
        </div>
        <div className="grid-span-6">
          <div className="flex gap">
            <h2 className={styles.subtitle}>
              {translate('podReporting.titleStatus')}
              {' - '}
              <span className={styles.subsubtitle}>
                {translate('podReporting.monthDoesNotApply')}
              </span>
            </h2>
          </div>
          <PodReportingDataTable
            data={status.map((item) => ({
              ...item,
              name:
                peStatuses?.find(({ id }) => id === item.name)?.description ||
                item.name,
            }))}
            header={translate('podReporting.table.status')}
            // onClick={onClickStatus}
          />
        </div>
      </div>
    </>
  );
};
