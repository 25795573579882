import { makeStyles } from '@mui/styles';

import { BackgroundColors, Fonts } from 'styleguide-v2';

export const PODSummaryCardStyles = makeStyles({
  chipsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.5rem',
    justifyContent: 'flex-end',
  },
  container: {
    fontSize: Fonts.fontSize,
  },
  textWithIcon: {
    marginTop: '0.25rem',
  },
  title: {
    fontSize: '1.25em',
    fontWeight: 500,
  },
  titleInfo: {
    display: 'flex',
    gap: '1rem',
    fontWeight: 500,
  },
  titleInfoCollapse: {
    '&&': {
      padding: '0.125rem',
      marginTop: '-0.125rem',
      marginRight: '-0.5rem',
    },
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1.5rem 2rem 1rem',
  },
  borderBottom: {
    borderBottom: `1px solid ${BackgroundColors.grayLine}`,
  },
  itemContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1rem 2rem',
  },
  subtitle: {
    fontSize: '1.125em',
    fontWeight: 500,
    minWidth: 'fit-content',
  },
});
