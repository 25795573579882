import moment, { Moment } from '../moment';

export class PodReportingSummary {
  businessDaysElapsed = 0;

  businessDaysRemaining = 0;

  businessDaysTotal = 0;

  completed = 0;

  perDay = 0;

  perDayGoal = 0;

  attempted = 0;

  notStarted = 0;

  total = 0;

  lastUpdatedAt?: Moment;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.perDay = Math.round(obj.perDay);
    this.perDayGoal = Math.round(obj.perDayGoal);
    this.lastUpdatedAt = moment.unix(obj.lastUpdatedAt);
  }
}

export default PodReportingSummary;
