import classNames from 'classnames';
import React from 'react';

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { Colors } from 'styleguide-v2/src/styles/Colors';

type ColorType = 'inherit' | 'action' | 'primary';
type SizeType = 'medium' | 'large' | 'small';

type Props = {
  className?: string;
  color?: ColorType;
  fontSize?: number;
  htmlColor?: string;
  open: boolean;
  size?: SizeType;
};

type StyleProps = {
  color: string;
  size: number;
};

const useStyles = makeStyles<Theme, StyleProps>({
  icon: {
    paddging: '0.25rem',
    position: 'relative',
    width: ({ size }: StyleProps) => `${size}px`,
    height: ({ size }: StyleProps) => `${size}px`,

    '&:before, &:after': {
      backgroundColor: ({ color }: StyleProps) => color,
      content: '""',
      position: 'absolute',
      transition: 'transform 0.25s ease',
    },

    '&:before': {
      left: 0,
      top: '50%',
      height: `2px`,
      width: '100%',
      marginTop: '-2px',
    },

    '&:after': {
      left: 0,
      top: '50%',
      height: `2px`,
      width: '100%',
      marginTop: '-2px',
    },
  },
  iconClose: {
    '&:before': { transform: 'rotate(-90deg)' },
  },
});

const getColors = (color: ColorType) => {
  if (color === 'primary') {
    return {
      color: Colors.green,
    };
  }
  if (color === 'action') {
    return {
      color: Colors.iconGray,
    };
  }
  return {
    color: Colors.iconGray,
  };
};

const getSize = (size: SizeType) => {
  if (size === 'small') return 10;
  if (size === 'large') return 30;
  return 20;
};

export const PlusMinusIcon = ({
  className,
  color = 'primary',
  fontSize,
  htmlColor,
  open,
  size = 'large',
}: Props) => {
  const currentColor = htmlColor || getColors(color)?.color;
  const currentSize = fontSize || getSize(size);
  const styles = useStyles({ color: currentColor, size: currentSize });
  return (
    <div
      className={classNames(className, styles.icon, !open && styles.iconClose)}
    />
  );
};
