import React from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import { PodMetricsSummary } from '@vestahealthcare/common/models';

import { PieChart } from 'styleguide-v2';

import { COLUMN_SIZE } from './constants';

type Props = {
  data: PodMetricsSummary[];
};

const useStyles = makeStyles({
  chart: {
    margin: '1rem auto',
  },
  container: {
    display: 'flex',
  },
  itemContainer: {
    width: `${COLUMN_SIZE}px`,
  },
});

export const PODSummaryTableMember = ({ data }: Props) => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      {data.map(({ id, topThreeLanguages, topThreeStates }, idx) => (
        <div className={styles.itemContainer} key={`pod-member-info-${idx}`}>
          <PieChart
            className={styles.chart}
            data={topThreeLanguages.map((lang) => ({
              id: `pod-${id}-language-${lang.item?.valueOf()}`,
              label: lang.item?.toString() || translate('global.other'),
              value: lang.count,
            }))}
            showLegend
            title="Languages"
            size="xs"
            type="outlined"
          />
          <PieChart
            className={styles.chart}
            data={topThreeStates.map((lang) => ({
              id: `pod-${id}-states-${lang.item?.valueOf()}`,
              label: lang.item?.toString() || translate('global.other'),
              value: lang.count,
            }))}
            showLegend
            title="States"
            size="xs"
            type="outlined"
          />
        </div>
      ))}
    </div>
  );
};
