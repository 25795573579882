import React from 'react';

import { makeStyles } from '@mui/styles';

import { RPMAdherenceLevel } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { PodMetricsSummary } from '@vestahealthcare/common/models';

import { PieChart } from 'styleguide-v2';

import { COLUMN_SIZE } from './constants';

type Props = {
  data: PodMetricsSummary[];
};

const useStyles = makeStyles({
  chart: {
    margin: '1rem auto',
  },
  container: {
    display: 'flex',
  },
  itemContainer: {
    width: `${COLUMN_SIZE}px`,
  },
});

export const PODSummaryTableProgram = ({ data }: Props) => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      {data.map((pod, idx) => (
        <div className={styles.itemContainer} key={`pod-program-${idx}`}>
          <PieChart
            className={styles.chart}
            color="status"
            data={[
              {
                id: `pod-${pod.id}-ccm-status-completed`,
                label: translate('common.status.completed'),
                value: pod.ccmCompletedCount,
              },
              {
                id: `pod-${pod.id}-ccm-status-attempted`,
                label: translate('common.status.attempted'),
                value: pod.ccmAttemptedCount,
              },
              {
                id: `pod-${pod.id}-ccm-status-not-started`,
                label: translate('common.status.notStarted'),
                value: pod.ccmNotStartedCount,
              },
            ]}
            size="xs"
            type="outlined"
          />
          <PieChart
            className={styles.chart}
            data={pod.onHoldCCMCounts.map((status) => ({
              id: `pod-${pod.id}-ccm-on-hold-${
                status.item?.valueOf() || 'unknown'
              }`,
              label: status.item?.toString() || translate('global.unknown'),
              value: status.count,
            }))}
            size="xs"
            type="outlined"
          />
          <PieChart
            className={styles.chart}
            color="status"
            data={[
              {
                id: `pod-${pod.id}-rpm-status-met`,
                label: RPMAdherenceLevel.MET.toString(),
                value: pod.rpmMetCount,
              },
              {
                id: `pod-${pod.id}-rpm-status-on-track`,
                label: RPMAdherenceLevel.ON_TRACK.toString(),
                value: pod.rpmMetCount,
              },
              {
                id: `pod-${pod.id}-rpm-status-at-risk`,
                label: RPMAdherenceLevel.AT_RISK.toString(),
                value: pod.rpmAtRiskCount,
              },
            ]}
            size="xs"
            type="outlined"
          />
          <PieChart
            className={styles.chart}
            color="status"
            data={[
              {
                id: `pod-${pod.id}-toc-status-completed`,
                label: translate('common.status.completed'),
                value: pod.tocDueCompCount,
              },
              {
                id: `pod-${pod.id}-toc-status-pending`,
                label: translate('common.status.pending'),
                value: pod.tocDuePenCount,
              },
              {
                id: `pod-${pod.id}-toc-status-reschedule`,
                label: translate('pods.metrics.card.rescheduleNeeded'),
                value: pod.tocDueRSCount,
              },
            ]}
            size="xs"
            type="outlined"
          />
        </div>
      ))}
    </div>
  );
};
