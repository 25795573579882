import React, { useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';

import { EmployeeRole } from '@vestahealthcare/common/enums';
import { PodMetricsSummaryEmployee } from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import {
  BackgroundColors,
  CollapseIcon,
  Colors,
  Table,
  TableItem,
} from 'styleguide-v2';

type RoleOptions =
  | 'np'
  | 'rn'
  | 'health_coach'
  | 'care_manager'
  | 'engagement_coordinator';
type Employee = TableItem & PodMetricsSummaryEmployee;

type Props = {
  employees: Employee[];
};

const COLUMNS_POD_EMPLOYEE_COMMON = [
  {
    headerName: 'Assigned',
    field: 'allocatedMembers',
    width: 150,
  },
  {
    headerName: 'Capacity',
    field: 'maxMembers',
    width: 150,
  },
];

const COLUMNS_POD_EMPLOYEES = [
  {
    headerName: 'Employee',
    field: 'employee.fullName',
    width: 150,
  },
  ...COLUMNS_POD_EMPLOYEE_COMMON,
];

const COLUMNS_POD_EMPLOYEE_ROLES = [
  {
    headerName: 'Employee',
    component: ({ role }: Employee) =>
      EmployeeRole.byKey[role]?.toString() || EMPTY,
    width: 150,
  },
  ...COLUMNS_POD_EMPLOYEE_COMMON,
  {
    headerName: ' ',
    component: (item: Employee) => <CollapseIcon open={!!item.open} />,
    width: 20,
  },
];

const useStyles = makeStyles({
  table: {
    '&& tbody': {
      '& > tr:not(.detail) > td:last-child': {
        padding: '0.5rem 1rem 0 0',
      },
      '& > tr.detail > td': {
        background: Colors.white,
        padding: '0',
      },
    },
  },
  subtable: {
    '&& thead': {
      display: 'none',
    },
    '&& tbody': {
      borderRadius: 0,
      boxShadow: 'none',
      borderLeft: `1px solid ${BackgroundColors.grayLine}`,
      borderRight: `1px solid ${BackgroundColors.grayLine}`,

      '&& tr td': {
        padding: '6px 12px',
      },
    },
  },
});

const SubTable = (props: Props) => {
  const styles = useStyles();
  return (
    <Table
      className={styles.subtable}
      config={{
        compact: true,
        data: props.employees,
        columns: COLUMNS_POD_EMPLOYEES,
        paginationOptions: [-1],
      }}
      whiteBackground
    />
  );
};

export const PodSummaryCardMemberAllocationTable = (props: Props) => {
  const styles = useStyles();
  const [data, setData] = useState<Omit<Employee, 'id' | 'employee'>[]>([]);

  useEffect(
    () =>
      setData(
        Object.entries(
          (props.employees || []).reduce((acc, item) => {
            const role = item.role as RoleOptions;
            if (acc[role]) {
              acc[role].allocatedMembers += item.allocatedMembers;
              acc[role].maxMembers += item.maxMembers;
            } else {
              acc[role] = {
                allocatedMembers: item.allocatedMembers,
                maxMembers: item.maxMembers,
              };
            }
            return acc;
          }, {} as { [role in RoleOptions]: Omit<Employee, 'id' | 'employee' | 'role'> }),
        ).map(([key, item]) => ({
          role: key as RoleOptions,
          ...item,
        })),
      ),
    [props.employees],
  );

  const onOpenDetail = (item: Employee) => {
    const itemIdx = data.findIndex((i) => i.role === item.role);
    if (itemIdx === -1) return;

    data[itemIdx].open = !data[itemIdx].open;
    if (data[itemIdx].open) {
      data[itemIdx].detail = (
        <SubTable
          employees={props.employees.filter(({ role }) => role === item.role)}
        />
      );
    } else {
      setTimeout(() => {
        delete data[itemIdx].detail;
        setData([...data]);
      }, 250);
    }
    setData([...data]);
  };

  return (
    <Table
      className={styles.table}
      config={{
        compact: true,
        data,
        detail: true,
        columns: COLUMNS_POD_EMPLOYEE_ROLES,
        paginationOptions: [-1],
      }}
      onClickRow={onOpenDetail}
      whiteBackground
    />
  );
};

export default PodSummaryCardMemberAllocationTable;
