import classNames from 'classnames';
import React, { useState } from 'react';

import PeopleIcon from '@mui/icons-material/PeopleAltOutlined';

import { translate } from '@vestahealthcare/common/i18n';
import { PodMetricsSummary } from '@vestahealthcare/common/models';

import {
  Collapse,
  CollapseIcon,
  Colors,
  IconButton,
  PieChart,
} from 'styleguide-v2';

import { PODSummaryCardStyles } from './styles';

type Props = {
  className?: string;
  pod: PodMetricsSummary;
};

export const PodSummaryCardUnreachable = ({ className, pod }: Props) => {
  const styles = PODSummaryCardStyles();
  const [openDetail, setOpenDetail] = useState<boolean>(false);

  return (
    <div className={className}>
      <div
        className={classNames(styles.itemContainer, 'clickable')}
        onClick={() => setOpenDetail(!openDetail)}
      >
        <span className={styles.subtitle}>
          {translate('pods.metrics.card.unreachable')}
        </span>
        <div className={styles.titleInfo}>
          <PeopleIcon htmlColor={Colors.textRed} fontSize="large" />
          <span className={classNames(styles.textWithIcon, 'red')}>
            {pod.unreachableCount}
          </span>
          <IconButton
            className={styles.titleInfoCollapse}
            onClick={() => setOpenDetail(!openDetail)}
            size="small"
          >
            <CollapseIcon open={openDetail} />
          </IconButton>
        </div>
      </div>
      <Collapse open={openDetail}>
        <div
          className={classNames(styles.itemContainer, 'flex center')}
          style={{ width: '100%' }}
        >
          <PieChart
            color="status"
            className="grid-span-6"
            data={[
              {
                id: 'status-30',
                label: `< 30 ${translate('global.days')}`,
                value: pod.unreachable30Count,
              },
              {
                id: 'status-30-60',
                label: `30-60 ${translate('global.days')}`,
                value: pod.unreachable3060Count,
              },
              {
                id: 'status-60-90',
                label: `60-90 ${translate('global.days')}`,
                value: pod.unreachable6090Count,
              },
              {
                id: 'status-90',
                label: `> 90 ${translate('global.days')}`,
                value: pod.unreachable90Count,
              },
            ]}
            showLegend
            getTooltipValue={(value, _, percentage) =>
              `${value.value} (${percentage}%)`
            }
            size="xs"
            type="outlined"
          />
        </div>
      </Collapse>
    </div>
  );
};
