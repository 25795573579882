import React from 'react';

import { Table } from 'styleguide-v2';

type Task = {
  name: string;
  total: number;
  completed: number;
  attempted: number;
  notStarted: number;
};

type Props = {
  tasks: Task[];
};

const COLUMNS_POD_TASKS = [
  {
    headerName: 'Task',
    field: 'name',
  },
  {
    headerName: 'Total',
    field: 'total',
    width: '17.5%',
  },
  {
    headerName: 'Completed',
    field: 'completed',
  },
  {
    headerName: 'Attempted',
    field: 'attempted',
  },
  {
    headerName: 'Not Started',
    field: 'notStarted',
  },
];

export const PodSummaryCardTaskTable = (props: Props) => (
  <Table
    config={{
      compact: true,
      data: props.tasks,
      columns: COLUMNS_POD_TASKS,
      paginationOptions: [-1],
    }}
    whiteBackground
  />
);

export default PodSummaryCardTaskTable;
