import { TaskStatus } from '../enums/TaskStatus';
import { PodMetricsSummaryData } from './PodMetricsSummaryData';

export class PodMetricsSummaryTask {
  name = '';

  notStarted = 0;

  attempted = 0;

  completed = 0;

  constructor(name: string, data: any) {
    this.name = name;
    this.attempted =
      (data as PodMetricsSummaryData[]).find(({ item }) => item === 'ATTEMPED')
        ?.count || 0;
    this.completed =
      (data as PodMetricsSummaryData[])?.find(
        ({ item }) => item === TaskStatus.COMPLETED.value,
      )?.count || 0;
    this.notStarted =
      (data as PodMetricsSummaryData[]).find(
        ({ item }) => item === TaskStatus.NOT_STARTED.value,
      )?.count || 0;
  }

  get total() {
    return this.attempted + this.notStarted + this.completed;
  }

  static sort(
    { total: a }: PodMetricsSummaryTask,
    { total: b }: PodMetricsSummaryTask,
  ) {
    return b - a;
  }
}

export default PodMetricsSummaryTask;
