import classNames from 'classnames';
import React from 'react';

import PodManagerIcon from '@mui/icons-material/SupervisedUserCircleOutlined';

import {
  PodMetricsSummary,
  PodMetricsSummaryEmployee,
} from '@vestahealthcare/common/models';

import { Card, Colors } from 'styleguide-v2';

import { PodSummaryCardBiannual } from './PodSummaryCardBiannual';
import { PodSummaryCardMemberAllocation } from './PodSummaryCardMemberAllocation';
import { PodSummaryCardMembers } from './PodSummaryCardMembers';
import { PodSummaryCardProgram } from './PodSummaryCardProgram';
import { PodSummaryCardTask } from './PodSummaryCardTask';
import { PodSummaryCardUnreachable } from './PodSummaryCardUnreachable';
import { PodSummaryCardWarnings } from './PodSummaryCardWarnings';
import { PODSummaryCardStyles } from './styles';

type Props = {
  className?: string;
  pod: PodMetricsSummary;
  employees: PodMetricsSummaryEmployee[];
  onOpenEmployees: () => void;
};

export const PODSummaryCard = ({
  className,
  employees,
  onOpenEmployees,
  pod,
}: Props) => {
  const styles = PODSummaryCardStyles();

  return (
    <Card className={classNames(className, styles.container)}>
      <div className={classNames(styles.titleContainer, styles.borderBottom)}>
        <span className={styles.title}>{pod.name}</span>
        <div className={styles.titleInfo}>
          <PodManagerIcon htmlColor={Colors.iconGreen} fontSize="large" />
          <span className={styles.textWithIcon}>{pod.manager.fullName}</span>
        </div>
      </div>
      <PodSummaryCardMembers className={styles.borderBottom} pod={pod} />
      <PodSummaryCardProgram className={styles.borderBottom} pod={pod} />
      <PodSummaryCardUnreachable className={styles.borderBottom} pod={pod} />
      <PodSummaryCardTask className={styles.borderBottom} pod={pod} />
      <PodSummaryCardBiannual className={styles.borderBottom} pod={pod} />
      <PodSummaryCardMemberAllocation
        className={styles.borderBottom}
        pod={pod}
        employees={employees}
        onOpenEmployees={onOpenEmployees}
      />
      <PodSummaryCardWarnings />
    </Card>
  );
};
