import { EncounterMethodOutcome } from './EncounterMethodOutcome';
import { Enum } from './Enum';

export class AppointmentStatus extends Enum {
  translationKey = 'appointmentStatus';

  static asArray: AppointmentStatus[] = [];

  static byKey: { [name: string]: AppointmentStatus } = {};

  static readonly COMP = new AppointmentStatus('COMP');

  static readonly KEPT = new AppointmentStatus('KEPT');

  static readonly PEN = new AppointmentStatus('PEN');

  static readonly N_S = new AppointmentStatus('N/S');

  static readonly R_S = new AppointmentStatus('R/S');

  static readonly VOID = new AppointmentStatus('VOID');

  static readonly CANC = new AppointmentStatus('CANC');

  static readonly CONFTESTED = new AppointmentStatus('CONFTESTED');

  static readonly CONFNOTST = new AppointmentStatus('CONFNOTST');

  static readonly UNCLVM_SMS = new AppointmentStatus('UNCLVM+SMS');

  static readonly UNC_LVM = new AppointmentStatus('UNC-LVM');

  static readonly UNC_SMS = new AppointmentStatus('UNC-SMS');

  static readonly CANCSMS = new AppointmentStatus('CANCSMS');

  constructor(public readonly value: string) {
    super();
    AppointmentStatus.asArray.push(this);
    AppointmentStatus.byKey[value] = this;
  }

  static getColor(status?: string) {
    switch (status) {
      case EncounterMethodOutcome.SUCCESSFUL.toString():
      case EncounterMethodOutcome.TRANSFERRED.toString():
      case AppointmentStatus.COMP.toString():
      case AppointmentStatus.KEPT.toString():
        return 'success';
      case EncounterMethodOutcome.INVALID_EMAIL.toString():
      case EncounterMethodOutcome.INVALID_FAX_NUMBER.toString():
      case EncounterMethodOutcome.PHONE_NUMBER_INVALID.toString():
      case EncounterMethodOutcome.UNABLE_TO_CONNECT.toString():
      case AppointmentStatus.N_S.toString():
      case AppointmentStatus.R_S.toString():
      case AppointmentStatus.VOID.toString():
      case AppointmentStatus.CANC.toString():
      case AppointmentStatus.CANCSMS.toString():
      case undefined:
        return 'error';
      default:
        return 'warning';
    }
  }
}

export default AppointmentStatus;
