import { BaseEnum } from './BaseEnum';

export class ProgramExtensionStatus extends BaseEnum {
  static readonly ACTIVE = 'ACTIVE';

  static readonly DELAYED_ESCALATED = 'DELAYED_ESCALATED';

  static readonly DELAYED_TECH_ISSUE = 'DELAYED_TECH_ISSUE';

  static readonly DELAYED_UNREACHABLE = 'DELAYED_UNREACHABLE';

  static readonly DENIED_DECLINED = 'DENIED_DECLINED';

  static readonly DISENROLLED = 'DISENROLLED';

  static readonly HOLD = 'HOLD';

  static readonly IN_PROGRESS = 'IN_PROGRESS';

  static readonly NOT_STARTED = 'NOT_STARTED';

  static readonly PENDING_AUTH = 'PENDING_AUTH';

  /** RPM Statuses */

  static readonly READY_FOR_OUTREACH = 'READY_FOR_OUTREACH';

  static getColorStatus(status?: ProgramExtensionStatus) {
    switch (status?.id) {
      case ProgramExtensionStatus.ACTIVE:
        return 'secondary';
      case ProgramExtensionStatus.IN_PROGRESS:
      case ProgramExtensionStatus.PENDING_AUTH:
      case ProgramExtensionStatus.HOLD:
        return 'warning';
      case ProgramExtensionStatus.DELAYED_ESCALATED:
      case ProgramExtensionStatus.DELAYED_TECH_ISSUE:
      case ProgramExtensionStatus.DELAYED_UNREACHABLE:
        return 'error';
      case ProgramExtensionStatus.DENIED_DECLINED:
      case ProgramExtensionStatus.DISENROLLED:
      case ProgramExtensionStatus.NOT_STARTED:
        return 'action';
      default:
        return 'info';
    }
  }
}

export default ProgramExtensionStatus;
