export class PodReportingTOCSummary {
  toc = 0;

  skilled = 0;

  tocSkilled = 0;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export default PodReportingTOCSummary;
