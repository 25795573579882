import classNames from 'classnames';
import { Moment } from 'moment';
import React, { ReactNode } from 'react';

import { makeStyles } from '@mui/styles';

import { Colors } from 'styleguide-v2/src/styles/Colors';
import { Fonts } from 'styleguide-v2/src/styles/Variables';

import { Spinner } from '../Spinner';
import { UpdatedBy } from '../UpdatedBy';

type Props = {
  className?: string;
  color?:
    | 'success'
    | 'info'
    | 'warning'
    | 'error'
    | 'closed'
    | 'default'
    | 'loading';
  date?: Moment | number;
  dateFormat?: string;
  size?: 'small' | 'medium';
  status?: string | ReactNode;
  subtitle?: ReactNode;
};

const useStyles = makeStyles({
  closed: { color: Colors.paleGray },
  closedText: { color: Colors.iconGray },
  default: { color: Colors.textBlack },
  error: { color: Colors.textRed },
  errorText: { color: Colors.textRed2 },
  info: { color: Colors.blue },
  infoText: { color: Colors.textBlue },
  loading: { color: Colors.iconGreen },
  loadingText: { color: Colors.textBlack },
  notStarted: { color: Colors.blueCold },
  notStartedText: { color: Colors.textBlueCold },
  success: { color: Colors.green },
  successText: { color: Colors.textGreen },
  warning: { color: Colors.gold },
  warningText: { color: Colors.textBlack },

  dateSmall: {
    '&& > div > span': {
      fontSize: `calc(${Fonts.fontSize} * 0.75)`,
      lineHeight: `calc(${Fonts.fontSize} * 1.185)`,
    },
  },

  iconSmallClass: {
    border: '4px solid',
    borderRadius: '4px',
    marginRight: '2px',
  },
  iconMediumClass: {
    border: '4px solid',
    borderRadius: '10px',
    marginRight: '2px',
    marginTop: '-1px',
  },
  text: {
    lineHeight: 'normal',
  },
  textSmall: {
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    lineHeight: `calc(${Fonts.fontSize} * 1.185)`,
  },

  subtitle: {
    color: Colors.iconGray,
    fontSize: `calc(${Fonts.fontSize} * 0.75)`,
    fontWeight: 500,
    lineHeight: `calc(${Fonts.fontSize} * 1.185)`,
  },

  statusContainer: {
    display: 'flex',
  },
  statusContainerSmall: {
    alignItems: 'baseline',
  },
  spinner: {
    marginRight: '0.25rem',
  },
});

export const StatusIndicator = ({
  className,
  color,
  date,
  dateFormat,
  size = 'medium',
  status,
  subtitle,
}: Props) => {
  const styles = useStyles();
  const iconClass = color ? styles[color] : '';
  // @ts-ignore
  const textClass = color ? styles[`${color}Text`] : styles.default;
  return (
    <div className={className}>
      <p
        className={classNames(
          'no-margin flex min-gap',
          styles.statusContainer,
          size === 'small' && styles.statusContainerSmall,
        )}
      >
        {color === 'loading' ? (
          <>
            <Spinner
              className={classNames(styles.spinner)}
              color="primary"
              width={8}
            />
          </>
        ) : (
          <div
            className={classNames(
              iconClass,
              size === 'small' && styles.iconSmallClass,
              size === 'medium' && styles.iconMediumClass,
            )}
          />
        )}
        <span
          className={classNames(
            'semi-bold',
            styles.text,
            size === 'small' && styles.textSmall,
            textClass,
          )}
        >
          {status}
        </span>
      </p>
      {(date || subtitle) && (
        <p
          className={classNames(
            'no-margin',
            size === 'small' && styles.dateSmall,
          )}
        >
          {date && <UpdatedBy date={date} dateFormat={dateFormat} />}
          {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
        </p>
      )}
    </div>
  );
};
