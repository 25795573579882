import classNames from 'classnames';
import React, { useState } from 'react';

import { RPMAdherenceLevel } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import { PodMetricsSummary } from '@vestahealthcare/common/models';

import {
  Chip,
  Collapse,
  CollapseIcon,
  IconButton,
  PieChart,
} from 'styleguide-v2';

import { getProgramColor } from '../utils';
import { PODSummaryCardStyles } from './styles';

type Props = {
  className?: string;
  pod: PodMetricsSummary;
};

export const PodSummaryCardProgram = ({ className, pod }: Props) => {
  const styles = PODSummaryCardStyles();
  const [openDetail, setOpenDetail] = useState<boolean>(false);

  return (
    <div className={className}>
      <div
        className={classNames(styles.itemContainer, 'clickable')}
        onClick={() => setOpenDetail(!openDetail)}
      >
        <span className={styles.subtitle}>
          {translate('pods.metrics.card.programSummary')}
        </span>
        <div className={styles.titleInfo}>
          <div className={styles.chipsContainer}>
            <Chip
              color={getProgramColor(pod.ccmCompletedPercetage)}
              type="contained-2"
            >
              <span className="bold">CCM: {pod.ccmCompletedPercetage}%</span>
            </Chip>
            <Chip
              color={getProgramColor(pod.rpmCompletedPercetage)}
              type="contained-2"
            >
              <span className="bold">RPM: {pod.rpmCompletedPercetage}%</span>
            </Chip>
            <Chip
              color={getProgramColor(pod.tocCompletedPercetage)}
              type="contained-2"
            >
              <span className="bold">
                TOC Appt: {pod.tocCompletedPercetage}%
              </span>
            </Chip>
            <Chip color="info" type="contained-2">
              <span className="bold">PERS: {pod.persCount}</span>
            </Chip>
          </div>
          <IconButton
            className={styles.titleInfoCollapse}
            onClick={() => setOpenDetail(!openDetail)}
            size="small"
          >
            <CollapseIcon open={openDetail} />
          </IconButton>
        </div>
      </div>
      <Collapse open={openDetail}>
        <div className={classNames(styles.itemContainer)}>
          <div className="grid-wrapper" style={{ width: '100%' }}>
            <PieChart
              color="status"
              className="grid-span-6"
              data={[
                {
                  id: `pod-${pod.id}-ccm-status-completed`,
                  label: translate('common.status.completed'),
                  value: pod.ccmCompletedCount,
                },
                {
                  id: `pod-${pod.id}-ccm-status-attempted`,
                  label: translate('common.status.attempted'),
                  value: pod.ccmAttemptedCount,
                },
                {
                  id: `pod-${pod.id}-ccm-status-not-started`,
                  label: translate('common.status.notStarted'),
                  value: pod.ccmNotStartedCount,
                },
              ]}
              showLegend
              size="xs"
              title="CCM (Enrolled)"
              type="outlined"
            />
            <PieChart
              className="grid-span-6"
              data={pod.onHoldCCMCounts.map((status) => ({
                id: `pod-${pod.id}-ccm-on-hold-${
                  status.item?.valueOf() || 'unknown'
                }`,
                label: status.item?.toString() || translate('global.unknown'),
                value: status.count,
              }))}
              showLegend
              size="xs"
              title="CCM (On Hold)"
              type="outlined"
            />
            <PieChart
              color="status"
              className="grid-span-6"
              data={[
                {
                  id: `pod-${pod.id}-rpm-status-met`,
                  label: RPMAdherenceLevel.MET.toString(),
                  value: pod.rpmMetCount,
                },
                {
                  id: `pod-${pod.id}-rpm-status-on-track`,
                  label: RPMAdherenceLevel.ON_TRACK.toString(),
                  value: pod.rpmMetCount,
                },
                {
                  id: `pod-${pod.id}-rpm-status-at-risk`,
                  label: RPMAdherenceLevel.AT_RISK.toString(),
                  value: pod.rpmAtRiskCount,
                },
              ]}
              showLegend
              size="xs"
              title="RPM"
              type="outlined"
            />
            <PieChart
              color="status"
              className="grid-span-6"
              data={[
                {
                  id: `pod-${pod.id}-toc-status-completed`,
                  label: translate('common.status.completed'),
                  value: pod.tocDueCompCount,
                },
                {
                  id: `pod-${pod.id}-toc-status-pending`,
                  label: translate('common.status.pending'),
                  value: pod.tocDuePenCount,
                },
                {
                  id: `pod-${pod.id}-toc-status-reschedule`,
                  label: translate('pods.metrics.card.rescheduleNeeded'),
                  value: pod.tocDueRSCount,
                },
              ]}
              showLegend
              size="xs"
              title="TOC Appt Due"
              type="outlined"
            />
            <div
              className={classNames(
                'grid-span-12 flex center',
                styles.chipsContainer,
              )}
            >
              <Chip color="info" type="contained-2">
                <span className="bold">
                  TOC {translate('pods.metrics.card.enrolled')}: {pod.tocCount}
                </span>
              </Chip>
              <Chip color="info" type="contained-2">
                <span className="bold">
                  Skilled {translate('pods.metrics.card.enrolled')}:{' '}
                  {pod.skilledCount}
                </span>
              </Chip>
              <Chip color="info" type="contained-2">
                <span className="bold">
                  PERS {translate('pods.metrics.card.enrolled')}:{' '}
                  {pod.persCount}
                </span>
              </Chip>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};
