import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';

import { translate } from '@vestahealthcare/common/i18n';
import {
  PodMetricsSummary,
  PodMetricsSummaryEmployee,
} from '@vestahealthcare/common/models';
import { DATE_FORMAT } from '@vestahealthcare/common/utils/constants';

import { Panel, Select, Tabs, ToggleButton } from 'styleguide-v2';

import {
  fetchPodMetricEmployees,
  fetchPodMetricsSummary,
} from '../../../../services/PodServices';
import { PODSummaryCard } from './components/PODSummaryCard';
import { PODSummaryTable } from './components/PODSummaryTable';

const NUMBER_OF_MONTHS = 12;
const POD_METRICS_INIT_MONTH = moment('2024-11-01', DATE_FORMAT);

const POD_METRICS_SUMMARY = 'pod-metrics-summary';
const POD_METRICS_FORECAST = 'pod-metrics-forecast';
const POD_METRICS_TABS = [
  { value: POD_METRICS_SUMMARY, label: translate('pods.metrics.tabs.summary') },
  {
    value: POD_METRICS_FORECAST,
    label: translate('pods.metrics.tabs.forecast'),
    disabled: true,
  },
];

const POD_METRICS_CARD = 'pod-metrics-card';
const POD_METRICS_TABLE = 'pod-metrics-table';
const POD_METRICS_VIEW_OPTIONS = [
  {
    label: translate('pods.metrics.viewOptions.card'),
    value: POD_METRICS_CARD,
  },
  {
    label: translate('pods.metrics.viewOptions.table'),
    value: POD_METRICS_TABLE,
  },
];

const useStyles = makeStyles({
  card: {
    flex: '1 1 50rem',
    maxWidth: '70rem',
    minWidth: '56rem',
  },
  date: {
    width: '15rem',
  },
});

export const PODsMetricsDashboard = () => {
  const styles = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [month, setMonth] = useState<Moment | undefined>(moment());
  const [selectedTab, setSelectedTab] = useState<string>(POD_METRICS_SUMMARY);
  const [selectedViewOptions, setSelectedViewOptions] = useState<string>(
    POD_METRICS_CARD,
  );

  const [pods, setPods] = useState<PodMetricsSummary[]>([]);
  const [employees, setEmployees] = useState<{
    [x: string]: PodMetricsSummaryEmployee[];
  }>({});

  const months = [] as Moment[];

  months.push(moment());
  // Last day of month for previous months
  for (let i = NUMBER_OF_MONTHS - 1; i > 0; i -= 1) {
    const monthToPush = moment()
      .subtract(NUMBER_OF_MONTHS - i, 'month')
      .endOf('month');
    if (POD_METRICS_INIT_MONTH.isSameOrBefore(monthToPush))
      months.push(monthToPush);
  }

  const getData = async () => {
    setLoading(true);
    try {
      const items = await fetchPodMetricsSummary({ month });
      setPods(items);
    } finally {
      setLoading(false);
    }
  };

  const getEmployees = async (id: number) => {
    setLoading(true);
    try {
      const items = await fetchPodMetricEmployees(id);
      employees[id.toString()] = items;
      setEmployees(employees);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [month]);

  return (
    <Panel id="pods-config-section">
      <Panel.Heading title={translate('pods.metrics.title')}>
        <Panel.Actions>
          <Select
            className={styles.date}
            data-cy="pod-metrics-filters-month"
            disableClearable
            getItemLabel={(date: Moment) =>
              date.format('MMM YYYY').toLocaleUpperCase()
            }
            items={months}
            onChange={(month?: Moment) => {
              setMonth(month);
            }}
            placeholder={translate('pods.metrics.common.month')}
            value={month}
          />
        </Panel.Actions>
        <Panel.Tabs>
          <div className="flex space-between">
            <Tabs
              disabled={loading}
              items={POD_METRICS_TABS}
              onChange={setSelectedTab}
              value={selectedTab}
            />
            <ToggleButton
              items={POD_METRICS_VIEW_OPTIONS}
              onChange={setSelectedViewOptions}
              value={selectedViewOptions}
              size="small"
            />
          </div>
        </Panel.Tabs>
      </Panel.Heading>
      <Panel.Body loading={loading}>
        {selectedViewOptions === POD_METRICS_CARD && (
          <div className="flex extra-gap wrap">
            {pods.map((pod) => (
              <PODSummaryCard
                className={styles.card}
                key={`pod-summary-${pod.id}`}
                pod={pod}
                employees={employees[pod.id.toString()] || []}
                onOpenEmployees={() => getEmployees(pod.id)}
              />
            ))}
          </div>
        )}
        {selectedViewOptions === POD_METRICS_TABLE && (
          <PODSummaryTable pods={pods} />
        )}
      </Panel.Body>
    </Panel>
  );
};

export default PODsMetricsDashboard;
