import classNames from 'classnames';
import React, { useState } from 'react';

import { PieValueType } from '@mui/x-charts';

import { MemberStatus } from '@vestahealthcare/common/enums';
import { translate } from '@vestahealthcare/common/i18n';
import {
  PodReportingData,
  PodReportingDataExtended,
  PodReportingOverview,
  ProgramExtensionStatus,
} from '@vestahealthcare/common/models';
import { EMPTY } from '@vestahealthcare/common/utils/constants';

import { Colors, PieChart, Summary, ToggleButton } from 'styleguide-v2';

import { PodReportingProgramTable } from './PodReportingProgramTable';
import { PodReportingStyles } from './PodReportingStyles';

const POD_REPORTING_TAB_VIEW_OPTIONS_TABLE = 'pod-reporting-view-option-table';
const POD_REPORTING_TAB_VIEW_OPTIONS_CHART = 'pod-reporting-view-option-chart';
const POD_REPORTING_TAB_VIEW_OPTIONS = [
  {
    label: translate('podReporting.tabs.table'),
    value: POD_REPORTING_TAB_VIEW_OPTIONS_TABLE,
  },
  {
    label: translate('podReporting.tabs.chart'),
    value: POD_REPORTING_TAB_VIEW_OPTIONS_CHART,
  },
];

type Props = {
  data?: PodReportingOverview;

  onClickStatus: (status: MemberStatus[]) => void;
  onClickProgram: (item: PodReportingData) => void;

  peStatuses: ProgramExtensionStatus[];
};

export const PodReportingOverviewPage = ({
  data,
  onClickStatus,
  onClickProgram,
  peStatuses,
}: Props) => {
  const styles = PodReportingStyles();

  const [visitViewOption, setVisitViewOption] = useState<string>(
    POD_REPORTING_TAB_VIEW_OPTIONS_TABLE,
  );

  if (!data) return <></>;

  const { biannual, programs, summary } = data;

  return (
    <>
      <div
        className={classNames(
          'flex spaced',
          styles.borderBottom,
          styles.paddingContainer,
        )}
      >
        <Summary
          color={Colors.textGreen}
          title={translate('podReporting.summary.total')}
          body={summary?.total !== undefined ? summary.total : EMPTY}
          footer={
            <>
              <span className="bold">{summary ? 100 : EMPTY}%</span>{' '}
              {translate('podReporting.summary.ofPatients')}
            </>
          }
        />
        <Summary
          color={Colors.iconGreen}
          title={translate('podReporting.summary.active')}
          body={summary?.active !== undefined ? summary.active : EMPTY}
          footer={
            <>
              <span className="bold">
                {summary?.total
                  ? Math.round((summary.active / summary.total) * 1000) / 10
                  : EMPTY}
                %
              </span>{' '}
              {translate('podReporting.summary.ofPatients')}
            </>
          }
          onClick={() => onClickStatus([MemberStatus.ACTIVE])}
        />
        <Summary
          color={Colors.mint}
          title={translate('podReporting.summary.accepted')}
          body={summary?.accepted !== undefined ? summary.accepted : EMPTY}
          footer={
            <>
              <span className="bold">
                {summary?.total
                  ? Math.round((summary.accepted / summary.total) * 1000) / 10
                  : EMPTY}
                %
              </span>{' '}
              {translate('podReporting.summary.ofPatients')}
            </>
          }
          onClick={() => onClickStatus([MemberStatus.ACCEPTED])}
        />
        <Summary
          color={Colors.gold}
          title={translate('podReporting.summary.passive')}
          body={summary?.passive !== undefined ? summary.passive : EMPTY}
          footer={
            <>
              <span className="bold">
                {summary?.total
                  ? Math.round((summary.passive / summary.total) * 1000) / 10
                  : EMPTY}
                %
              </span>{' '}
              {translate('podReporting.summary.ofPatients')}
            </>
          }
          onClick={() => onClickStatus([MemberStatus.PASSIVE])}
        />
        <Summary
          color={Colors.iconGray}
          title={translate('podReporting.summary.deceased')}
          body={summary?.deceased !== undefined ? summary.deceased : EMPTY}
          footer={
            <>
              <span className="bold">
                {summary?.total
                  ? Math.round((summary.deceased / summary.total) * 1000) / 10
                  : EMPTY}
                %
              </span>{' '}
              {translate('podReporting.summary.ofPatients')}
            </>
          }
          onClick={() =>
            onClickStatus([
              MemberStatus.DECEASED_NON_ONBOARDED,
              MemberStatus.DECEASED_ONBOARDED,
            ])
          }
        />
        <Summary
          color={Colors.iconGray}
          title={translate('podReporting.summary.disenrolled')}
          body={
            summary?.disenrolled !== undefined ? summary.disenrolled : EMPTY
          }
          footer={
            <>
              <span className="bold">
                {summary?.total
                  ? Math.round((summary.disenrolled / summary.total) * 1000) /
                    10
                  : EMPTY}
                %
              </span>{' '}
              {translate('podReporting.summary.ofPatients')}
            </>
          }
          onClick={() =>
            onClickStatus([
              MemberStatus.DISENROLLED_HOSPICE,
              MemberStatus.DISENROLLED_INSTITUTIONALIZED,
              MemberStatus.DISENROLLED_OON_INSURANCE,
              MemberStatus.DISENROLLED_UNRESPONSIVE,
              MemberStatus.DISENROLLED_UNSUPPORTED_STATE,
              MemberStatus.DISENROLLED_WITHDRAW,
            ])
          }
        />
      </div>
      <div className={classNames('grid-wrapper', styles.borderContainer)}>
        <div className="grid-span-6">
          <div className="flex spaced wrap">
            <div className="flex gap">
              <h2 className={styles.subtitle}>
                {translate('podReporting.titleProgram')}
              </h2>
            </div>
          </div>
          <PodReportingProgramTable
            actionItems={['CCM', 'RPM', 'TOC']}
            data={programs}
            getItemLabel={({ name }: PodReportingDataExtended) =>
              peStatuses?.find(({ id }) => id === name)?.description || name
            }
            header={translate('podReporting.table.program')}
            onClick={onClickProgram}
          />
        </div>
        <div className="grid-span-6">
          <div className="flex spaced wrap">
            <div className="flex gap">
              <h2 className={styles.subtitle}>
                {translate('podReporting.titleVisit')}
              </h2>
            </div>
            <ToggleButton
              color="info"
              items={POD_REPORTING_TAB_VIEW_OPTIONS}
              onChange={setVisitViewOption}
              size="xs"
              value={visitViewOption}
            />
          </div>
          {visitViewOption === POD_REPORTING_TAB_VIEW_OPTIONS_TABLE && (
            <PodReportingProgramTable
              data={biannual}
              header={translate('podReporting.table.biannual')}
            />
          )}
          {visitViewOption === POD_REPORTING_TAB_VIEW_OPTIONS_CHART && (
            <div className="flex gap wrap">
              <PieChart
                color="status"
                data={
                  biannual?.length
                    ? biannual[0]?.subtotals?.map((item) => ({
                        value: item.total,
                        label: item.name,
                        id: item.id || item.name,
                      })) || []
                    : []
                }
                getTooltipValue={(
                  value: Partial<PieValueType>,
                  _: number,
                  percentage: number,
                ) => `${value.value} (${percentage}%)`}
                showLegend
                size="xs"
                title={translate('podReporting.table.due')}
                type="outlined"
              />
              <PieChart
                color="status"
                data={
                  biannual?.length
                    ? [
                        ...(biannual[1]?.subtotals?.map((item, idx) => ({
                          color: idx === 0 ? Colors.error : Colors.textRed2,
                          value: item.total,
                          label: item.name,
                          id: item.id || item.name,
                        })) || []),
                      ]
                    : []
                }
                getTooltipValue={(
                  value: Partial<PieValueType>,
                  _: number,
                  percentage: number,
                ) => `${value.value} (${percentage}%)`}
                showLegend
                size="xs"
                title={translate('podReporting.table.overdue')}
                type="outlined"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
